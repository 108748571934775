import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import SettingsContainer from "./SettingsContainer";
import ChangePic from "../popups/ChangePic";
import Message from "../Message";
// Icons
import exclamationIcon from "../../assets/icons/exclamation.svg";
import calendarIcon from "../../assets/icons/calendar_grey.svg";
// Contexts
import { AccountSettingsConsumer } from "../../contexts/AccountSettingsContext";
import { LoginConsumer } from "../../contexts/LoginContext";

const PersonalData = () => {
  const [isAvatarEditOpen, setIsAvatarEditOpen] = useState(false);
  const { imgSrc, setImgSrc } = LoginConsumer();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    selectedDate,
    setSelectedDate,
    handleUpdatePersonalData,
    errMsg,
    successMsg,
  } = AccountSettingsConsumer();

  const saveImg = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgSrc(reader.result.toString());
    };
    reader.readAsDataURL(file);
    onCloseAvatarEdit();
  };

  const onCloseAvatarEdit = () => {
    setIsAvatarEditOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SettingsContainer>
        <div>
          <h3 className="text-3xl text-center lg:text-left font-semibold uppercase mb-6">
            CHANGE NAME AND PHOTO
          </h3>
          <div className="w-44 h-44 mb-4 mx-auto lg:ml-0">
            <img
              src={imgSrc}
              alt="avatar"
              className="mx-auto lg:ml-0 w-full h-full rounded-full"
            />
          </div>
          <div>
            <button
              className="bg-dark-600 text-green border-2 border-green py-2 px-3 rounded cursor-pointer block mx-auto lg:ml-0"
              onClick={() => setIsAvatarEditOpen(true)}
            >
              UPLOAD NEW PHOTO
            </button>
            <p className="flex items-start gap-2 mt-6">
              <img src={exclamationIcon} alt="Exclamation mark" />{" "}
              <span className="text-dark-400 leading-5">
                You can only change your personal information once every three
                months
              </span>
            </p>
          </div>
          <form className="mt-5" onSubmit={handleUpdatePersonalData}>
            <div className="mb-3 mt-6 relative w-full">
              <input
                type="text"
                name="fisrtname"
                placeholder="First name"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
              />
              <label
                htmlFor="firstName"
                className="bg-dark-600 text-light text-xs px-1 absolute -top-2 left-2 rounded"
              >
                Fisrt name
              </label>
            </div>
            <div className="mb-3 mt-6 relative w-full">
              <input
                type="text"
                name="lastname"
                id="lastName"
                placeholder="First name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
              />
              <label
                htmlFor="lastName"
                className="bg-dark-600 text-light text-xs px-1 absolute -top-2 left-2 rounded"
              >
                Last name
              </label>
            </div>
            <div className="mb-3 mt-6 relative w-full">
              <input
                type="tel"
                name="phone_number"
                placeholder="Phone number"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
              />
              <label
                htmlFor="phoneNumber"
                className="bg-dark-600 text-light text-xs px-1 absolute -top-2 left-2 rounded"
              >
                Number
              </label>
            </div>
            <div className="my-6 relative w-full">
              <ReactDatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd-MMMM-yyyy"
                showYearDropdown
                scrollableMonthYearDropdown
                className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
              />
              <label className="bg-dark-600 text-xs px-1 absolute -top-2 left-2 rounded">
                Date of birth
              </label>
              <img
                src={calendarIcon}
                alt="choose your birth date"
                className="absolute top-3 right-3 pointer-events-none"
              />
            </div>

            <Message msgType="success" message={successMsg} text="left" />
            <Message msgType="error" message={errMsg} text="left" />

            <button
              type="submit"
              className="p-3 bg-green text-white rounded block w-fit mx-auto lg:ml-0"
            >
              SAVE CHANGES
            </button>
          </form>
        </div>
      </SettingsContainer>
      <ChangePic
        isOpen={isAvatarEditOpen}
        saveImg={saveImg}
        onCloseAvatarEdit={onCloseAvatarEdit}
      />
    </>
  );
};

export default PersonalData;
