import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { getAge } from "../utils/Helpers";
import { toast } from "react-toastify";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const SignupContext = createContext();

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;

const SignupProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("gold");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [matchPassword, setMatchPassword] = useState("");
  const [validMatchPassword, setValidMatchPassword] = useState(false);
  const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [passwordInputIcon, setPasswordinputIcon] = useState(<BsEyeFill />);
  const [selectedDate, setSelectedDate] = useState(new Date("2001/04/29"));
  const [isAdult, setIsAdult] = useState(false);

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(false);
  };

  const showHidePassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
      setPasswordinputIcon(<BsEyeSlashFill />);
    } else {
      setPasswordInputType("password");
      setPasswordinputIcon(<BsEyeFill />);
    }
  };

  const onChangeRecaptcha = (value) => {
    setIsRecaptchaVerified(true);
    return value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const v = PWD_REGEX.test(password);
    if (!v) {
      setErrMsg("Invalid Entry");
      return;
    }
    navigate("/terms-of-use");
  };

  const handleRegister = async (data) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_REGISTER,
        JSON.stringify({
          username: email,
          first_name: firstName,
          last_name: lastName,
          password,
          age: getAge(selectedDate.toISOString().slice(0, 10)),
          phone_number: phoneNumber,
          birth_date: selectedDate.toISOString().slice(0, 10),
          package: selectedPlan,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 201) {
        subscribePlan(data, email, firstName);
      }
    } catch (err) {
      if (!err.response) {
        toast.error("No Server Response!", {
          position: toast.POSITION.BOTTOM_CENTER,
          theme: "dark",
        });
      } else if (
        err.response.status === 400 &&
        err.response.data.username[0] ===
          "A user with that username already exists."
      ) {
        toast.error("This user is already exists!", {
          position: toast.POSITION.BOTTOM_CENTER,
          theme: "dark",
        });
      } else {
        toast.error("Registration Failed!", {
          position: toast.POSITION.BOTTOM_CENTER,
          theme: "dark",
        });
      }
    }
  };

  const subscribePlan = async (data, email, firstName) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SUBSCRIPTION,
        JSON.stringify({
          email: email,
          subscription_id: data.subscriptionID,
          order_id: data.orderID,
          payment_source: "paypal",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 201) {
        if (location.pathname === "/terms-of-use") {
          setShowMsg(true);
          setSuccessMsg(
            `Hey ${firstName}, we have sent you an activation email, please check it! (Check Spam if you don't find it)`
          );
        } else if (location.pathname === "/account-settings/subscribe-plan") {
          toast.success("Subscription paid successfully.", {
            position: toast.POSITION.BOTTOM_CENTER,
            theme: "dark",
          });
        }
      }
    } catch (err) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_CENTER,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatchPassword(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    const age = getAge(selectedDate.toISOString().slice(0, 10));
    if (age >= 18) {
      setIsAdult(true);
    } else {
      setIsAdult(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    let timeoutId;
    if (showMsg) {
      timeoutId = setTimeout(() => {
        setShowMsg(false);
        setSuccessMsg("");
        setErrMsg("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setPassword("");
        setMatchPassword("");
        navigate("/login");
      }, 8000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showMsg, navigate]);

  return (
    <SignupContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        onModalClose,
        selectedPlan,
        selectPlan,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        selectedDate,
        setSelectedDate,
        password,
        setPassword,
        validPassword,
        setValidPassword,
        matchPassword,
        setMatchPassword,
        validMatchPassword,
        setValidMatchPassword,
        matchPasswordFocus,
        setMatchPasswordFocus,
        passwordInputType,
        setPasswordInputType,
        passwordInputIcon,
        setPasswordinputIcon,
        showHidePassword,
        handleRegister,
        handleSubmit,
        errMsg,
        successMsg,
        isRecaptchaVerified,
        setIsRecaptchaVerified,
        onChangeRecaptcha,
        isAdult,
        subscribePlan,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};

function SignupConsumer() {
  return useContext(SignupContext);
}

export { SignupProvider, SignupConsumer };
