import { createContext, useContext, useState } from "react";
import { LoginConsumer } from "./LoginContext";
import { toast } from "react-toastify";
import axios from "axios";

const ManagePlansContext = createContext();

const ManagePlansProvider = ({ children }) => {
  const { user, getUser } = LoginConsumer();
  const selectedPlan = user.plan.toLowerCase();
  const userId = user.id;
  const planStatus = user.plan_status;
  const [isPlansModalOpen, setIsPlansModalOpen] = useState(false);
  const [isFreezModalOpen, setIsFreezModalOpen] = useState(false);
  const [newSelectedPlan, setNewSelectedPlan] = useState(() =>
    localStorage.getItem("newSelectedPlan")
      ? localStorage.getItem("newSelectedPlan")
      : ""
  );
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const onModalClose = () => {
    setIsPlansModalOpen(false);
  };

  const selectPlan = (plan) => {
    setNewSelectedPlan(plan);
    setIsPlansModalOpen(false);
    localStorage.setItem("newSelectedPlan", plan);
  };

  const onPlansModalClose = (e) => {
    e.preventDefault();
    setIsPlansModalOpen(false);
  };

  const onFreezModalClose = (e) => {
    e.preventDefault();
    setIsFreezModalOpen(false);
  };

  const freezePlan = async () => {
    try {
      const response = await axios.post(
        `https://stage.srv.apicalfitness.com/api/users/${userId}/change-plan/`,
        JSON.stringify({
          package: selectedPlan,
          package_status: "frozen",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 200) {
        getUser(authTokens.access);
        toast.success(`"${selectedPlan}" Plan is frozen!`);
      }
    } catch (err) {
      if (!err.response) {
        toast.error("No Server Response", {
          position: "bottom-center",
        });
      } else {
        toast.error("Operation Failed", {
          position: "bottom-center",
        });
      }
    }
  };

  return (
    <ManagePlansContext.Provider
      value={{
        isPlansModalOpen,
        setIsPlansModalOpen,
        onModalClose,
        selectedPlan,
        selectPlan,
        onPlansModalClose,
        isFreezModalOpen,
        setIsFreezModalOpen,
        onFreezModalClose,
        newSelectedPlan,
        setNewSelectedPlan,
        freezePlan,
        planStatus,
      }}
    >
      {children}
    </ManagePlansContext.Provider>
  );
};

function ManagePlansConsumer() {
  return useContext(ManagePlansContext);
}

export { ManagePlansProvider, ManagePlansConsumer };
