import { LoginConsumer } from "../../contexts/LoginContext";
import Message from "../Message";

const ForgotPasswordComponent = () => {
  const {
    accountEmail,
    setAccountEmail,
    handleForgotPassword,
    successMsg,
    errMsg,
    isShowRecoverBtn,
  } = LoginConsumer();

  return (
    <div className="max-w-7xl container px-4 sm:px-6 lg:px-12 xl:px-0 relative flex flex-col items-center lg:block">
      <div className="mb-6">
        <h1 className="text-left text-4xl w-80 mb-2 font-semibold">
          FORGOT PASSWORD
        </h1>
        <p>
          Enter your account email to recover <br /> a password
        </p>
      </div>
      <form className="w-80 text-center" onSubmit={handleForgotPassword}>
        <div className="my-3">
          <input
            type="email"
            name="accountEmail"
            placeholder="Account Email"
            value={accountEmail}
            onChange={(e) => setAccountEmail(e.target.value)}
            required
            className="max-w-sm px-2 py-3 bg-dark-800 text-light rounded w-80 placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
          />
        </div>

        <Message msgType="success" message={successMsg} text="left" />
        <Message msgType="error" message={errMsg} text="left" />

        {isShowRecoverBtn ? (
          <button
            type="submit"
            className="text-white bg-green py-3 px-4 rounded w-52 ml-0 block mt-8"
          >
            RECOVER PASSWORD
          </button>
        ) : null}
      </form>
    </div>
  );
};

export default ForgotPasswordComponent;
