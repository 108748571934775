import { Outlet } from "react-router-dom";

const AccountSettings = () => {
  return (
    <main className="bg-dark text-white min-h-screen relative account-settings">
      <section className="container mx-auto px-4 sm:px-6 lg:px-12 xl:px-0 flex justify-center">
        <Outlet />
      </section>
    </main>
  );
};

export default AccountSettings;
