import { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";
import startCase from "lodash.startcase";

const PartnerContext = createContext(null);

const PartnerProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [categories, setCategories] = useState([]);
  const [formCategories, setFormCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filteredFormCategories, setFilteredFormCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [callAvailability, setCallAvailability] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showMsg, setShowMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({
      email,
      address,
      subcategories: filteredSubcategories,
      organization_name: name,
      phone_number: phone,
      is_available_for_call: callAvailability,
    });

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      !name ||
      !email ||
      !address ||
      !filteredSubcategories.length ||
      phone.length === 0
    ) {
      setErrMsg("Please fill all the fields!");
      return;
    }

    if (!re.test(email)) {
      setErrMsg("Please enter valid email address!");
      return;
    }

    try {
      let formSubCategories = [];
      filteredSubcategories.map((cat) => {
        formSubCategories.push(cat.value);
        return cat.value;
      });

      const response = await axios.post(
        process.env.REACT_APP_BECOME_PARTNER,
        JSON.stringify({
          email,
          address,
          subcategories: formSubCategories,
          organization_name: name,
          phone_number: phone,
          is_available_for_call: callAvailability,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 200) {
        setSuccessMsg(
          "Thank you for your interest, we'll contact you as soon as possible"
        );
        setErrMsg("");
        setShowMsg(true);
        setName("");
        setEmail("");
        setPhone("");
        setAddress("");
        setFilteredFormCategories([]);
        setFilteredSubcategories([]);
      }
    } catch (err) {
      console.log(err);
      if (!err.response) {
        setErrMsg("No Server Response, verify your internet connection.");
        setSuccessMsg("");
        setShowMsg(true);
      } else {
        setErrMsg("Operation Failed, Please try again!");
        setSuccessMsg("");
        setShowMsg(true);
      }
    }
  };

  const getCategories = async () => {
    const response = await axios.get(process.env.REACT_APP_CATEGORIES, {
      headers: {
        "App-Type": "Web",
      },
    });
    const data = response.data;
    if (response.status === 200) {
      const formatedCategories = data.map((categorie) => {
        return {
          label: categorie.title,
          value: categorie.id,
        };
      });
      setFormCategories(formatedCategories);
      setCategories(data);
    }
  };

  const getSubcategories = async () => {
    const response = await axios.get(process.env.REACT_APP_SPORTS, {
      headers: {
        "App-Type": "Web",
      },
    });
    const data = response.data;
    if (response.status === 200) {
      const formatedSubCategories = data.map((categorie) => {
        return {
          label: startCase(categorie.name),
          value: categorie.id,
        };
      });
      setSubcategories(formatedSubCategories);
    }
  };

  const handleChangeCategories = (items) => {
    setFilteredFormCategories(items);
    let tempCategories = [...categories];

    tempCategories = tempCategories.filter((category) =>
      items.map((item) => item.label).includes(category.title)
    );

    // filter subcategories by categories onchange
    if (tempCategories.length) {
      const newSubcategories = tempCategories
        .map((category) =>
          category.sports.map((sport) => {
            return { label: sport.name, value: sport.id };
          })
        )
        .flat();
      setSubcategories(newSubcategories);
    } else {
      getSubcategories();
    }
  };

  const handleChangeSubcategories = (items) => {
    setFilteredSubcategories(items);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMsg(false);
      setSuccessMsg("");
      setErrMsg("");
    }, 10000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showMsg]);

  useEffect(() => {
    getCategories();
    getSubcategories();
  }, []);

  return (
    <PartnerContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        address,
        setAddress,
        phone,
        setPhone,
        categories,
        formCategories,
        filteredSubcategories,
        handleChangeCategories,
        subcategories,
        setSubcategories,
        handleChangeSubcategories,
        callAvailability,
        setCallAvailability,
        handleSubmit,
        successMsg,
        errMsg,
        filteredFormCategories,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

const PartnerConsumer = () => {
  return useContext(PartnerContext);
};

export { PartnerConsumer, PartnerProvider };
