import { HashLink } from "react-router-hash-link";
import errorImg from "../assets/images/not_found.svg";

const PageNotFound = () => {
  return (
    <main className="h-screen flex flex-col items-center justify-center">
      <div className="max-w-md">
        <img src={errorImg} alt="Error Page" className="max-w-full" />
      </div>
      <p className="my-7 text-4xl lg:text-5xl font-oswald">
        Oops Page Not Found!
      </p>
      <HashLink
        to="/#"
        className="bg-yellow text-dark py-2 px-3 text-lg font-semibold rounded"
      >
        Return Home
      </HashLink>
    </main>
  );
};

export default PageNotFound;
