import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="post-content bg-light text-dark privacy-policy">
      <div className="container">
        <h2
          className="wp-block-heading fusion-responsive-typography-calculated"
          data-fontsize="30"
          data-lineheight="39px"
          style={{ lineHeight: 1.3 }}
        >
          <b data-fusion-font="true">DEFINITION</b>
        </h2>
        <p>&nbsp;</p>
        <ul className="list-disc list-inside">
          <li>
            “We”, “Our” and “Us” shall mean and refer to the Company as the
            context so requires.
          </li>
          <li>
            “You”, “Your”, “Yourself”, and “User”, shall mean and refer to a
            natural and legal individual who uses the Platform and who is
            competent to enter into binding contracts, as per Indian laws.
          </li>
          <li>“Service”, shall refer to service provided by the platform.</li>
          <li>
            “Platform”, “Application”, shall mean or refer to the Application
            created by this Company which provides Client avail services of the
            Company via the use of this platform.
          </li>
          <li>
            “Personal Information” shall mean and refer to any personally
            identifiable information that We may collect from You such as Name,
            Email address, Phone number(Optional), etc.
          </li>
          <li>
            “Company” shall mean and refer to Apical Fitness Corp., having its
            registered office at 18 King Street East, Suite 1400 Toronto,
            Ontario M5C 1C4
          </li>
        </ul>

        <h2
          className="wp-block-heading fusion-responsive-typography-calculated"
          data-fontsize="30"
          data-lineheight="39px"
          style={{ lineHeight: 1.3 }}
        >
          <b data-fusion-font="true">WHAT INFORMATION DO WE COLLECT?</b>
        </h2>
        <p>
          <b>Personal Information you disclose to us</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            We collect personal information that you deliberately provide us
            when you register on the Application, express an interest in
            obtaining information about us or our products and Services when you
            participate in activities on the Application, or otherwise when you
            contact us. The personal information that we collect depends on the
            context of your interactions with us and the Application. The
            personal information we collect may include the following:
          </li>
        </ul>
        <p>
          <b>Personal Information Provided by You</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            We collect names, phone numbers, email addresses, passwords, profile
            image or authentication data, location and similar other
            information.
          </li>
          <li>
            All the personal information that you provide to us must be true,
            complete and accurate and you must notify us of any changes to such
            personal information.
          </li>
        </ul>
        <p>
          <b>Information collected through our Application</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            We collect information regarding your push notifications when you
            use our Application.
          </li>
          <li>
            If you use our Application, we also collect the following
            information:
          </li>
          <li>
            Location: Location data provided by user for locating nearest gyms
            and to add their gym location so that other users will find gym
            location in map with user consent.
          </li>
          <li>
            Camera Permisson: We require camera permission to capture picture
            for profile picture and scan QR code, we donot record any videos and
            audio through the app.
          </li>

          <li>
            Payment Data. We may collect data necessary to process your payment
            if you make purchases, such as your payment instrument number, and
            the security code associated with your payment instrument. All
            payment data is stored by Pay Pal . You may find their privacy
            notice link(s) here:{" "}
            <a href="https://www.paypal.com/us/legalhub/privacy-full">
              {" "}
              PayPal Privacy Policy
            </a>
          </li>
          <li>
            Notes: We save your notes on our server in completely encrypted
            format.(END to END encryption.) with user consent. Also, we dont
            collect any users location, location is asked for gym location fetch
            and add.
          </li>
        </ul>
        <h2
          className="fusion-responsive-typography-calculated"
          data-fontsize="30"
          data-lineheight="39px"
          style={{ lineHeight: 1.3 }}
        >
          <b data-fusion-font="true">HOW DO WE USE YOUR INFORMATION ?</b>
        </h2>
        <p>&nbsp;</p>
        <p>
          <b>Personal Information you disclose to us</b>
        </p>
        <p>
          We use personal information collected through our Application for a
          variety of business purposes described below. We compute your personal
          information for the purposes in dependence on our sanctioned business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for consent with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p>
          <b>We use the information we collect or receive:</b>
        </p>
        <ul className="list-disc list-inside">
          <li>To facilitate account creation and login process.</li>
          <li>
            The information provided by you shall be used for creation of
            account and alternative login by using information such as phone
            number and email address.
          </li>
          <li>
            All the personal information that you provide to us must be true,
            complete and accurate and you must notify us of any changes to such
            personal information.
          </li>
        </ul>
        <p>
          <b>Request feedback.</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            We may use your information to request feedback and to contact about
            your use of our Application.
          </li>
          <li>To enable user-to user communication.</li>
        </ul>
        <p>
          <b>To send administrative information to you.</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            We may use your personal information to send you services and new
            feature information and/or information about changes to our terms,
            conditions and policies.
          </li>
        </ul>
        <p>
          <b>To protect our Services.</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            We may use your information as part of our efforts to keep our
            Application safe and secure (for example, for monitoring and
            prevention from fraud).
          </li>
        </ul>
        <p>
          <b>To Deliver targeted advertising to you.</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            We may use your information to develop and display personalized
            content and advertising tailored to your interests and/or location
            and to measure its effectiveness.
          </li>
          <li>To respond to user inquiries/offer support to users.</li>
          <li>
            We may use your information to respond to your inquiries and solve
            any potential issues you might have with the use of our Services.
          </li>
        </ul>
        <p>
          <b>HOW LONG DO WE KEEP YOUR INFORMATION?</b>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law. When we have
          no legitimate business need to process your personal information, we
          will either delete or anonymize such information, or if this is not
          possible then we will securely store your personal information and
          isolate it from any further processing until deletion is possible.
        </p>
        <p>&nbsp;</p>
        <h2
          className="fusion-responsive-typography-calculated"
          data-fontsize="30"
          data-lineheight="39px"
          style={{ lineHeight: 1.3 }}
        >
          <b data-fusion-font="true">HOW DO WE KEEP YOUR INFORMATION SAFE ?</b>
        </h2>
        <p>&nbsp;</p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. We never store your personal sensitive data in raw format
          instead in encrypted format. However, despite our provisions and
          efforts to secure your information, no electronic transmission over
          the internet or information storage technology can be guaranteed to be
          100% secure, so we cannot pledge or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security, and improperly collect, access, steal, or
          modify your information. However we will do our best to protect your
          personal information, transmission of personal information to and from
          our Application is at your own risk. You should only access the
          Application within a secure environment.
        </p>
        <p>&nbsp;</p>
        <h2
          className="fusion-responsive-typography-calculated"
          data-fontsize="30"
          data-lineheight="39px"
          style={{ lineHeight: 1.3 }}
        >
          <b data-fusion-font="true">WHAT ARE YOUR PRIVACY RIGHTS?</b>
        </h2>
        <p>&nbsp;</p>
        <p>
          If you would at any time like to review or change the information in
          your account, you can:
        </p>
        <ul className="list-disc list-inside">
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>
          <b>Opting out of email marketing:</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            You can unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be
            removed from the marketing email list – however, we may still
            communicate with you, for example to send you emails related to our
            services are necessary for the administration and use of your
            account, to respond to service requests, or for other non-marketing
            purposes. To otherwise opt-out, you may: Access your account setting
            and update your preferences.
          </li>
        </ul>
        <p>
          <b>Account information:</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            You can control personal information such as Name, Email and Profile
            Picture either to be revealed to other user or not which are to be
            displayed by other user.
          </li>
        </ul>
        <p>
          <b>Control of your password:</b>
        </p>
        <ul className="list-disc list-inside">
          <li>
            You are entirely responsible for maintaining the confidentiality of
            your password. It is important that you protect it against
            unauthorized access of your account and information by choosing your
            password carefully and keeping your password and device secure.
          </li>
        </ul>
        <h2
          className="fusion-responsive-typography-calculated"
          data-fontsize="30"
          data-lineheight="39px"
          style={{ lineHeight: 1.3 }}
        >
          <b data-fusion-font="true">DO WE MAKE UPDATES TO THIS NOTICE ?</b>
        </h2>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          important changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <p>
          <b data-fusion-font="true">
            HOW CAN YOU REVIEW OR UPDATE THE DATA WE COLLECT FROM YOU ?
          </b>
        </p>
        <p>&nbsp;</p>
        <p>
          Based on registration, you can review and change the information you
          submitted at the stage of registration, except you can change your
          name only for five(5) times An option for facilitating such change
          shall be present on the Platform and such change shall be facilitated
          by the User.
        </p>
        <p>&nbsp;</p>
        <h2
          className="fusion-responsive-typography-calculated"
          data-fontsize="30"
          data-lineheight="39px"
          style={{ lineHeight: 1.3 }}
        >
          <b data-fusion-font="true">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE ?
          </b>
        </h2>
        <p>&nbsp;</p>
        <p>
          If you have questions or comments about this notice or the content or
          practices of our Application, you may email us at
          info@apicalfitness.com or by phone at +1 647 947 3003, or by visiting
          this link, or by post to:
        </p>
        <p data-fusion-font="true">Apical Fitness Corp.</p>
        <p data-fusion-font="true">Toronto , Ontario M5C 1C4,</p>
        <p data-fusion-font="true">Canada,</p>
        <p data-fusion-font="true">
          18 King Street East, Suite 1400 Toronto, Ontario M5C 1C4.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
