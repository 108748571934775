function NewFeatures() {
  return (
    <div className="gradient-yellow-to-bottom md:gradient-yellow-to-right">
      <div
        style={{ color: "#303030" }}
        className="container flex flex-col items-center justify-center gap-24 px-8 py-12 md:flex-row md:py-24"
      >
        {features.map((feature, index) => {
          return (
            <div
              key={index}
              className="flex flex-col items-center justify-center gap-2"
            >
              {feature.icon}
              <p className="mt-2 text-xl font-bold text-center uppercase">
                {feature.title}
              </p>
              <p className="text-center">{feature.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const features = [
  {
    icon: (
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.7357 33.7094C37.0399 34.4959 36.3097 35.2729 35.5463 36.0363C24.5045 47.0781 10.6711 51.1467 4.64832 45.1241C0.519291 40.9948 1.13335 33.1948 5.48567 25.1398M11.452 16.6603C12.1761 15.837 12.9378 15.0243 13.7359 14.2262C24.7775 3.18449 38.6109 -0.88416 44.6338 5.13855C48.7656 9.27043 48.148 17.0785 43.7874 25.1393M35.5463 14.2262C46.5878 25.2678 50.6564 39.1012 44.6338 45.1241C38.6109 51.1467 24.7775 47.0781 13.7359 36.0363C2.69425 24.9948 -1.3744 11.1613 4.64832 5.13855C10.6711 -0.88416 24.5045 3.18449 35.5463 14.2262ZM27.1579 25.0776C27.1579 26.4972 26.0071 27.648 24.5875 27.648C23.1679 27.648 22.0172 26.4972 22.0172 25.0776C22.0172 23.658 23.1679 22.5072 24.5875 22.5072C26.0071 22.5072 27.1579 23.658 27.1579 25.0776Z"
          stroke="#574D27"
          stroke-width="3.19688"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    title: "Flexibility",
    text: "Workout anywhere and any time you like",
  },
  {
    icon: (
      <svg
        width="53"
        height="52"
        viewBox="0 0 53 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.7696 23.8111H17.7822M22.1114 32.4693H17.7822M35.0987 15.1529H17.7822M43.7569 25.9756V14.7199C43.7569 11.0831 43.7569 9.26474 43.0491 7.87568C42.4266 6.65381 41.4333 5.66041 40.2114 5.03784C38.8222 4.33008 37.0039 4.33008 33.367 4.33008H19.5139C15.8771 4.33008 14.0587 4.33008 12.6696 5.03784C11.4478 5.66041 10.4544 6.65381 9.83179 7.87568C9.12402 9.26474 9.12402 11.0831 9.12402 14.7199V37.2313C9.12402 40.8682 9.12402 42.6864 9.83179 44.0756C10.4544 45.2975 11.4478 46.2908 12.6696 46.9134C14.0587 47.6212 15.8771 47.6212 19.5139 47.6212H26.4405M35.0987 34.6338L45.9215 45.4566M45.9215 34.6338L35.0987 45.4566"
          stroke="#303030"
          stroke-width="3.19688"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    title: "No Commitments",
    text: "No contracts, no hidden fees. Cancel whenever you want",
  },
  {
    icon: (
      <svg
        width="47"
        height="50"
        viewBox="0 0 47 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5528 23.0259L3.88163 7.51902C2.88457 5.73554 2.38605 4.84267 2.47006 4.11379C2.54335 3.47602 2.88181 2.89974 3.40234 2.52619C3.99895 2.09569 5.0209 2.0957 7.06479 2.0957H12.158C12.9176 2.0957 13.2974 2.09571 13.6381 2.20504C13.9395 2.30299 14.2174 2.46016 14.4541 2.67199C14.7215 2.90887 14.9143 3.23684 15.2999 3.89056L23.6402 18.04L31.9806 3.89056C32.3662 3.23684 32.559 2.90887 32.8264 2.67199C33.0631 2.46016 33.341 2.30299 33.6425 2.20504C33.983 2.09571 34.3629 2.0957 35.1225 2.0957H40.2157C42.2595 2.0957 43.2815 2.09569 43.8781 2.52619C44.3987 2.89974 44.7371 3.47602 44.8105 4.11379C44.8946 4.84267 44.3959 5.73554 43.3988 7.51902L34.7278 23.0259M20.2217 29.4287L23.6402 27.151V38.5397M20.7914 38.5397H26.489M34.1152 22.3768C39.9003 28.1577 39.9003 37.5329 34.1152 43.3139C28.33 49.0971 18.9505 49.0971 13.1653 43.3139C7.38018 37.5329 7.38018 28.1577 13.1653 22.3768C18.9505 16.5936 28.33 16.5936 34.1152 22.3768Z"
          stroke="#524829"
          stroke-width="3.19688"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    title: "Single Membership",
    text: "Visit multiple facilities with one subscription",
  },
];

export default NewFeatures;
