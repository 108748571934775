import { Link } from "react-router-dom";
import appImg from "../assets/images/download_app.png";
import appStoreImg from "../assets/images/App_Store.png";
import googlePlayImg from "../assets/images/Google_Play.png";
import checkedIcon from "../assets/icons/checked.svg";
import linesPic from "../assets/images/topographic_4.svg";
import { useEffect } from "react";
import Container from "../components/Container";

const DownloadApp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="bg-dark text-white py-12 min-h-screen flex items-center relative overflow-hidden">
      <img
        src={linesPic}
        alt="Lines background"
        className="absolute top-0 right-0 hidden lg:block"
      />
      <Container className="lg:flex items-center justify-between relative">
        <div>
          <h1 className="uppercase text-4xl lg:text-5xl font-semibold text-center sm:text-left">
            Download our app
          </h1>
          <p className="mt-4 text-lg font-light">
            It is free for you. Our app would allow you:
          </p>
          <ul className="mt-8">
            <li className="flex items-center gap-2 text-lg font-light">
              <img src={checkedIcon} alt="checked icon" />
              <span>use app as a ticket to gyms</span>
            </li>
            <li className="flex items-center gap-2 mt-3 text-lg font-light">
              <img src={checkedIcon} alt="checked icon" />
              <span>manage your subscription</span>
            </li>
            <li className="flex items-center gap-2 mt-3 text-lg font-light">
              <img src={checkedIcon} alt="checked icon" />
              <span>discover gyms by your plan</span>
            </li>
            <li className="flex items-center gap-2 mt-3 text-lg font-light">
              <img src={checkedIcon} alt="checked icon" />
              <span>follow your history visiting and save favorite gyms</span>
            </li>
          </ul>

          <div className="mt-12 flex flex-col items-center sm:flex-row gap-4">
            <a
              target="_blank"
              href="https://apps.apple.com/in/app/apical-fitness/id6444069453"
              rel="noreferrer"
            >
              <img src={appStoreImg} alt="App store link" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.apicalfitness.apicalfitness"
              rel="noreferrer"
            >
              <img src={googlePlayImg} alt="Google play link" />
            </a>
          </div>
        </div>
        <div className="hidden lg:block">
          <img src={appImg} alt="Our App" style={{ height: "650px" }} />
        </div>
      </Container>
    </main>
  );
};

export default DownloadApp;
