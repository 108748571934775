import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import PasswordChecklist from "react-password-checklist";
import Message from "./Message";
import "react-datepicker/dist/react-datepicker.css";
// Contexts
import { SignupConsumer } from "../contexts/SignupFormContext";
// Data
import plansData from "../data/plansData";
// Icons
import checkedIcon from "../assets/icons/checked.svg";
import uncheckedIcon from "../assets/icons/uncheck_red.svg";
import calendarIcon from "../assets/icons/calendar.svg";
import { BsInfoCircle } from "react-icons/bs";

const SignupForm = () => {
  const {
    setIsModalOpen,
    selectedPlan,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    selectedDate,
    setSelectedDate,
    password,
    setPassword,
    validPassword,
    matchPassword,
    setMatchPassword,
    validMatchPassword,
    matchPasswordFocus,
    setMatchPasswordFocus,
    passwordInputType,
    passwordInputIcon,
    showHidePassword,
    handleSubmit,
    errMsg,
    successMsg,
    isRecaptchaVerified,
    setIsRecaptchaVerified,
    onChangeRecaptcha,
    isAdult,
  } = SignupConsumer();
  const firstnameRef = useRef(null);

  useEffect(() => {
    setIsRecaptchaVerified(false);
  }, []);

  useEffect(() => {
    firstnameRef.current.focus();
  }, []);

  return (
    <form className="max-w-sm signup-form" onSubmit={handleSubmit}>
      {/* Name */}
      <div className="flex flex-col gap-2 sm:flex-row">
        <input
          ref={firstnameRef}
          type="text"
          name="fisrtname"
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          className="px-2 py-3 rounded sm:w-1/2 bg-dark-800 text-light placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
        />
        <input
          type="text"
          name="lastname"
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          className="px-2 py-3 rounded sm:w-1/2 bg-dark-800 text-light placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
        />
      </div>
      {/* Email */}
      <div className="my-3">
        <input
          type="email"
          name="Username"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full max-w-sm px-2 py-3 rounded bg-dark-800 text-light placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
        />
      </div>
      {/* Phone number */}
      <div className="my-3">
        <input
          type="tel"
          name="Phone number"
          placeholder="Phone number"
          value={phoneNumber}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;

            // if value is not blank, then test the regex
            if (e.target.value === "" || re.test(e.target.value)) {
              setPhoneNumber(e.target.value);
            }
          }}
          required
          className="w-full max-w-sm px-2 py-3 rounded bg-dark-800 text-light placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
        />
      </div>
      {/* Birthdate */}
      <div className="relative mt-4 mb-3">
        <DatePicker
          name="Birth date"
          selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
          }}
          dateFormat="dd-MMMM-yyyy"
          showYearDropdown
          scrollableMonthYearDropdown
          required
          className="w-full max-w-sm px-2 py-3 rounded bg-dark-800 text-dark-400 focus:outline focus:text-green focus:outline-green"
        />
        <img
          src={calendarIcon}
          alt="choose your birth date"
          className="absolute pointer-events-none top-3 right-3"
        />
        <span className="absolute px-1 text-xs rounded text-green bg-dark-600 -top-2 left-2">
          Date of birth
        </span>
        {!isAdult ? (
          <Message
            message="You can signup only if you are over 18"
            msgType="error"
          />
        ) : null}
      </div>
      {/* Password */}
      <div className="relative my-3">
        <input
          type={passwordInputType}
          name="password"
          placeholder="Password"
          minLength={6}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="w-full max-w-sm px-2 py-3 rounded bg-dark-800 text-light placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
        />
        <span
          className="absolute right-0 w-8 h-8 cursor-pointer top-4 text-dark-400"
          onClick={showHidePassword}
        >
          {passwordInputIcon}
        </span>
      </div>
      {/* Check password if it matches the requirements */}
      <PasswordChecklist
        rules={["capital", "lowercase", "number", "minLength"]}
        minLength={6}
        value={password}
        messages={{
          capital: "Uppercase letters",
          lowercase: "Lowercase letters",
          number: "Numbers",
          minLength: "Password has more than 6 characters",
        }}
        iconComponents={{
          ValidIcon: <img src={checkedIcon} alt="Checked" />,
          InvalidIcon: <img src={uncheckedIcon} alt="Unchecked" />,
        }}
        className="my-3 text-left password-checklist"
      />
      {/* Repeat password */}
      <div className="relative">
        <input
          type="password"
          name="repeat_password"
          placeholder="Repeat Password"
          value={matchPassword}
          required
          onChange={(e) => setMatchPassword(e.target.value)}
          onFocus={() => setMatchPasswordFocus(true)}
          className="w-full max-w-sm px-2 py-3 rounded bg-dark-800 text-light placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
        />
        <span className="absolute right-0 w-8 h-8 top-4">
          {validMatchPassword && matchPassword ? (
            <img src={checkedIcon} alt="Valid password" />
          ) : validMatchPassword || !matchPassword ? (
            <img src={uncheckedIcon} alt="Unchecked icon" />
          ) : (
            <img src={uncheckedIcon} alt="Unchecked icon" />
          )}
        </span>
        {matchPasswordFocus && !validMatchPassword ? (
          <p className="flex items-center gap-2 my-3 text-white">
            <BsInfoCircle size="1.4rem" /> Must match the first password input
            field.
          </p>
        ) : null}
      </div>
      <div className="flex items-center justify-center my-3">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          theme="dark"
          onChange={onChangeRecaptcha}
        />
      </div>
      {plansData.map((plan) =>
        plan.name === selectedPlan ? (
          <button
            type="submit"
            key={plan.id}
            disabled={
              !validPassword ||
              !validMatchPassword ||
              !isRecaptchaVerified ||
              !isAdult
                ? true
                : false
            }
            className={
              "bg-green text-white py-3 px-2 block w-full rounded text-center my-3 disabled:opacity-60 disabled:cursor-not-allowed"
            }
          >
            SIGN UP FOR {plan.price}$
          </button>
        ) : null
      )}

      <Message msgType="error" message={errMsg} />
      <Message msgType="success" message={successMsg} />

      <button
        onClick={() => setIsModalOpen(true)}
        type="button"
        className="block w-full px-2 py-3 mt-3 text-center border-4 rounded bg-dark text-green border-green"
      >
        SELECT OTHER PLAN
      </button>
      <Link
        to="/login"
        className="block mx-auto mt-8 text-center text-green w-fit"
      >
        I HAVE AN ACCOUNT
      </Link>
    </form>
  );
};

export default SignupForm;
