import goldBg from "../assets/images/gold.jpg";
import silverBg from "../assets/images/silver.png";
import bronzeBg from "../assets/images/bronze.jpg";

const plansData = [
  {
    id: 0,
    name: "gold",
    price: "99.99",
    discount: true,
    discountedPrice: "44",
    bg: goldBg,
    features: [
      /*
      "Unlimited access to all locations",
      "5 Training programs",
      "Free fitness consultation",
      "Personal trainer",
      */
      "Unlimited access to Silver, Bronze and Gold locations",
      "No Contract, No Commitment",
      "Cancel anytime",
      "Gold members free exclusive gym merchandise: Premium Stainless Steel Water Bottle, Prestigious Tank Top, Classy Hat, and Elite Bag",
    ],
  },
  {
    id: 1,
    name: "silver",
    price: "49.99",
    discount: true,
    discountedPrice: "31",
    bg: silverBg,
    features: [
      /*
      "Unlimited access to all bronze and silver locations",
      "5 Training programs",
      "Free fitness consultation",
      */
      "Unlimited access to Silver and Bronze locations",
      "No Contract, No Commitment",
      "Cancel anytime",
    ],
  },
  {
    id: 2,
    name: "bronze",
    price: "24.99",
    discount: false,
    bg: bronzeBg,
    features: [
      /*
      "Unlimited access to all bronze locations",
      "5 Training programs",
      */
      "Unlimited access to Bronze locations",
      "No Contract, No Commitment",
      "Cancel anytime",
    ],
  },
];

export default plansData;
