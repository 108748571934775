import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { PartnersConsumer } from "../../contexts/PartnersContext";
// Components
import PartnerCard from "./PartnerCard";
import PartnerFilter from "./PartnerFilter";
import PuffLoader from "react-spinners/PuffLoader";
// Images
import defaultImg from "../../assets/images/rundofase_2.jpg";
import { LoginConsumer } from "../../contexts/LoginContext";
import Container from "../Container";

const DiscoverComponent = ({ mode, selectedPlan }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const { filteredPartners, loading } = PartnersConsumer();
  const [filteredPartnersBySelectedPlan, setFilteredPartnersBySelectedPlan] = useState([]);
  const { isAuth } = LoginConsumer();
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [visiblePartners, setVisiblePartners] = useState(isBigScreen ? 8 : 4);

  useEffect(() => {
    let newFilteredPartners = filteredPartners;
    if(selectedPlan === 'bronze') {
      newFilteredPartners = newFilteredPartners.filter((partner) => partner.subscribed_plans.includes('bronze'));
    } else if (selectedPlan === 'silver') {
      newFilteredPartners = newFilteredPartners.filter((partner) => partner.subscribed_plans.includes('silver') || partner.subscribed_plans.includes('bronze'));
    }
    setFilteredPartnersBySelectedPlan(newFilteredPartners)
  }, [filteredPartners, selectedPlan]);

  useEffect(() => {
    if (
      !(
        filteredPartnersBySelectedPlan.slice(0, visiblePartners).length ===
        filteredPartnersBySelectedPlan.length
      )
    ) {
      setIsShowMore(true);
    } else {
      setIsShowMore(false);
    }
  }, [filteredPartnersBySelectedPlan, visiblePartners, isAuth, isBigScreen]);

  const showMoreItems = () => {
    setVisiblePartners((c) => (isBigScreen ? c + 4 : c + 2));
  };

  return (
    <section
      className={`${mode === "light" ? "bg-light-400 text-dark" : ""} `}
      id="discover"
    >
      <Container className="flex flex-col lg:flex-row gap-6 justify-between items-center">
        <h2 className="font-bold text-4xl md:text-5xl text-yellow">Discover</h2>
        <PartnerFilter mode={mode} />
      </Container>

      {loading ? (
        <h3 className="text-6xl flex items-center justify-center pt-16">
          <PuffLoader color="#25AB75" />
        </h3>
      ) : filteredPartnersBySelectedPlan.length ? (
        <Container className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-8 place-items-center my-12">
          {filteredPartnersBySelectedPlan.slice(0, visiblePartners).map((partner) => {
            const { name, categories, id, address_line1, images } = partner;

            return (
              <PartnerCard
                mode={mode}
                key={id}
                categories={categories}
                address={address_line1}
                image={images[0]?.image || defaultImg}
                name={name}
                id={id}
              />
            );
          })}
        </Container>
      ) : (
        <h3 className="text-6xl text-center w-full py-5">No Partners Found!</h3>
      )}
      <div className="text-center mt-12">
        {isShowMore ? (
          <button
            className="p-3 bg-green text-white rounded"
            onClick={showMoreItems}
          >
            SHOW MORE
          </button>
        ) : null}
      </div>
    </section>
  );
};

export default DiscoverComponent;
