import { useEffect } from "react";
import { SignupConsumer } from "../contexts/SignupFormContext";
// Images
import womanPic from "../assets/images/woman.svg";
import linesPic from "../assets/images/topographic_6.svg";
// Components
import Plan from "../components/Plan";
import SignupForm from "../components/SignupForm";
import ChoosePlan from "../components/ChoosePlan";
import IntercomHelp from "../components/IntercomHelp";

const Signup = () => {
  const { isModalOpen, onModalClose, selectedPlan } = SignupConsumer();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      onModalClose();
    };
  }, []);

  return (
    <section className="relative min-h-screen py-6 text-white bg-dark">
      <img
        src={womanPic}
        alt="Fitness woman"
        className="absolute top-0 right-0 hidden max-h-full lg:block"
      />
      <img
        src={linesPic}
        alt="Lines background"
        className="absolute top-0 left-0 max-h-full"
      />
      <div className="container relative flex flex-wrap items-center justify-center gap-24 px-4 mx-auto max-w-7xl sm:px-6 lg:px-12 xl:px-0 lg:justify-start">
        <div className="text-center">
          <div className="max-w-sm mb-4">
            <h1 className="mb-2 text-5xl font-bold uppercase">
              Become a member
            </h1>
            <p className="text-md">
              When you sign up, you also purchase and subscribe to the plan you
              choose
            </p>
          </div>
          <SignupForm />
        </div>
        <div className="flex flex-col items-center justify-center max-w-sm gap-2 text-center">
          <h2 className="text-3xl font-bold uppercase">Selected plan</h2>
          <p className="text-sm">
            Pay one subscription and visit different gyms. Use our app as a
            ticket to the gyms
          </p>
          <Plan planName={selectedPlan} discover={true} />
        </div>
      </div>
      <ChoosePlan isOpen={isModalOpen} onClose={onModalClose} />

      <IntercomHelp />
    </section>
  );
};

export default Signup;
