import React from "react";
import { HashLink } from "react-router-hash-link";
import { SignupConsumer } from "../contexts/SignupFormContext";
import { LoginConsumer } from "../contexts/LoginContext";
// Data
import plansData from "../data/plansData";
// Icons
import checkedIcon from "../assets/icons/checked.svg";
import { Link } from "react-router-dom";
import { ManagePlansConsumer } from "../contexts/ManagPlansContext";

const Plan = ({ planName, isSelectPlanBtn, joinNowBtn, freez, discover }) => {
  const { selectPlan } = SignupConsumer();
  const { isAuth } = LoginConsumer();

  return (
    <div className="bg-dark-600 text-light w-fit rounded-md py-8 mt-5 max-w-sm self-stretch shadow shadow-dark flex flex-col">
      {plansData.map((plan) => {
        const { id, name, bg, price, features } = plan;
        return name === planName ? (
          <React.Fragment key={id}>
            <div className="pb-6 text-center">
              <span className="text-6xl font-bold">
                <span className="text-3xl">$</span>
                {price}
              </span>
              <span className="text-neutral">/Biweekly</span>
            </div>
            <div className="relative flex items-center justify-center">
              <img
                src={bg}
                alt={`${name} background`}
                className="w-full h-16"
              />
              <h5 className="absolute text-xl font-bold tracking-widest uppercase text-dark-600">
                {name}
              </h5>
            </div>
            <ul
              className="pt-2 pb-6 px-4 text-neutral"
              style={{ minHeight: "190px" }}
            >
              <li className="flex mt-4 gap-3">
                <div className="shrink-0 mt-1">
                  <img src={checkedIcon} alt="checked icon" />
                </div>
                <span className="text-left">
                  <span className="line-through mr-2">$499</span>
                  <span className="font-bold">$0</span> Activation Fee (limited
                  time offer)
                </span>
              </li>
              {features.map((feature, index) => (
                <li key={index} className="flex mt-4 gap-3">
                  <div className="shrink-0 mt-1">
                    <img src={checkedIcon} alt="checked icon" />
                  </div>
                  {feature.substring(0, 3) === "---" ? (
                    <span className="text-left line-through">
                      {feature.substring(3)}
                    </span>
                  ) : (
                    <span className="text-left">{feature}</span>
                  )}
                </li>
              ))}
            </ul>
            <div className="mt-auto">
              {isSelectPlanBtn ? (
                <button
                  className="text-white bg-green py-3 px-6 mt-4 mb-6 rounded text-center block w-fit mx-auto"
                  onClick={() => selectPlan(planName)}
                >
                  SELECT PLAN
                </button>
              ) : null}
              {joinNowBtn && !isAuth ? (
                <Link
                  to="/signup"
                  className="text-green border-2 border-green py-3 px-3 sm:px-6 mt-4 mb-6 rounded text-center block w-fit mx-auto"
                  onClick={() => selectPlan(planName)}
                >
                  JOIN NOW
                </Link>
              ) : null}
              {freez ? (
                <button className="py-2 px-3 bg-dark-600 border-4 border-green rounded text-green text-xl block mx-auto">
                  FREEZE PLAN
                </button>
              ) : null}
              {discover ? (
                <Link
                  to={`/discover?selected_plan=${planName}`}
                  className="text-green text-center block w-fit mx-auto text-sm sm:text-base"
                >
                  Discover related gyms
                </Link>
              ) : null}
            </div>
          </React.Fragment>
        ) : null;
      })}
    </div>
  );
};

export default Plan;
