import { useEffect } from "react";
import { AccountSettingsConsumer } from "../../contexts/AccountSettingsContext";
import SettingsContainer from "./SettingsContainer";
import Message from "../Message";

const ChangeEmail = () => {
  const {
    newEmail,
    setNewEmail,
    passwordInputType,
    newEmailPassword,
    setNewEmailPassword,
    showHidePassword,
    passwordInputIcon,
    handleChangeEmail,
    successMsg,
    errMsg,
  } = AccountSettingsConsumer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SettingsContainer>
      <div className="sm:w-96">
        <h3 className="text-3xl text-center lg:text-left font-semibold uppercase mb-3">
          CHANGE EMAIL
        </h3>
        <p className="mb-6">You will have to confirm new email.</p>
        <form className="mt-5" onSubmit={handleChangeEmail}>
          <div className="mb-6 relative w-full">
            <input
              type="email"
              name="new_email"
              placeholder="New email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
          </div>

          <div className="my-6 relative">
            <input
              type={passwordInputType}
              name="password"
              placeholder="Enter your current password"
              id="password"
              value={newEmailPassword}
              onChange={(e) => setNewEmailPassword(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
            <label
              htmlFor="password"
              className="bg-dark-600 text-xs px-1 absolute -top-2 left-2 rounded"
            >
              Current password
            </label>
            <span
              className="absolute right-0 top-4 w-8 h-8 text-dark-400 cursor-pointer"
              onClick={showHidePassword}
            >
              {passwordInputIcon}
            </span>
          </div>

          <Message msgType="success" message={successMsg} text="left" />
          <Message msgType="error" message={errMsg} text="left" />

          <button
            type="submit"
            className="p-3 bg-green text-white rounded block w-fit mx-auto lg:ml-0"
          >
            CHANGE EMAIL
          </button>
        </form>
      </div>
    </SettingsContainer>
  );
};

export default ChangeEmail;
