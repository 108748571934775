import { useState, useEffect } from "react";
import { LoginConsumer } from "../contexts/LoginContext";
import { SignupConsumer } from "../contexts/SignupFormContext";
import Message from "../components/Message";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const TermsOfUse = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { isAuth } = LoginConsumer();
  const {
    password,
    matchPassword,
    validMatchPassword,
    successMsg,
    handleRegister,
    selectedPlan,
  } = SignupConsumer();

  const createSubscription = (_data, actions) => {
    return actions.subscription.create({
      plan_id:
        selectedPlan === "gold"
          ? process.env.REACT_APP_PAYPAL_GOLD_ID
          : selectedPlan === "silver"
          ? process.env.REACT_APP_PAYPAL_SILVER_ID
          : process.env.REACT_APP_PAYPAL_BRONZE_ID,
    });
  };

  const onApprove = (data, _actions) => {
    handleRegister(data);
  };

  const onError = () => {
    toast.error("Something went wrong!", {
      position: toast.POSITION.BOTTOM_CENTER,
      theme: "dark",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="py-12 min-h-screen privacy-policy relative">
      <section className="container max-w-2xl mx-auto px-4 sm:px-6 lg:px-12 xl:px-0">
        <h1 className="text-2xl font-inter font-semibold">Terms of use</h1>
        <p>Last updated: September 15, 2022</p>
        <div
          className="mt-8 p-2 max-h-screen overflow-y-scroll overflow-x-auto font-light leading-7 rounded privacy-policy__content"
          style={{ border: "1px solid #333" }}
        >
          <div>
            <p>
              Welcome{" "}
              <a href="https://to www.apicalfitness.com">
                to www.apicalfitness.com
              </a>{" "}
              and{" "}
              <a href="https://www.apicalfitness.ca">www.apicalfitness.ca</a>{" "}
              collectively known as 'Apical Fitness'. Thank you for taking the
              time to visit us. The Terms of Use, Privacy Policy, the Mobile
              Devices Terms of Use and all policies posted on our site, set out
              the terms on which we offer you access to and use of our website,
              services, applications and tools collectively known as 'Services'.
              You can find an overview of our policies here. All of our
              policies, the Mobile Devices Terms of Use, and the Privacy Policy
              are incorporated into these Terms of Use. By using our services,
              you agree to comply with all the above when accessing or using our
              Services. <br />
              Apical Fitness is provided to you by Apical Fitness Corp., 18 King
              Street East, Suite 1400, Toronto, Ontario, M5C 1C4
            </p>
            <h3 className="my-3 font-inter font-semibold">Your Account.</h3>
            <p>
              In order for users to access and use some or a portion of the
              Services, you may be required to register with us by providing
              your email address and set a password collectively known as your
              'Account'. The email address you provide will be your login
              address, and you are solely responsible for maintaining the
              confidentiality of your password. You are solely responsible for
              all activities that occur under your Account. Therefore, you
              should protect your password and make your password difficult for
              others to guess. You will not transfer your Account to another
              party without our consent. <br />
              You give us permission to access, store, and use your information
              provided by you to us and as may be described in our Privacy
              Policy. If you believe your Account may have been compromised or
              misused by someone else, contact us immediately at Apical Fitness
              Customer Support.
            </p>
            <h3 className="my-3 font-inter font-semibold">
              Using the Services.
            </h3>
            <p>
              By using our services, you agree that you will post in the
              appropriate categories or areas and you agree not to violate the
              following terms:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser, provide
                analytics, remember information about you such as your language
                preference or login information.
              </li>
              <li>
                Violate any international or domestic laws or our Prohibited
                Content Policy;
              </li>
              <li>To make false or misleading statements or posts;</li>
              <li>Infringe or violate any third-party rights;</li>
              <li>
                Distribute or contain spam, chain letters, or pyramid schemes;
              </li>
              <li>
                Distribute viruses, worms, malwares or any other technologies
                that may harm Apical Fitness as a whole or its intended users;
              </li>
              <li>
                Not to copy, modify, or distribute any other person's content;
              </li>
              <li>
                Not to use any robot, spider, scraper or other automated means
                to access Apical Fitness and collect content for any purpose
                without our express written permission;
              </li>
              <li>
                Shall not harvest or otherwise collect information about others,
                including email addresses, without their consent;
              </li>
              <li>
                Use measures used to prevent or restrict access to Apical
                Fitness services.
              </li>
            </ul>

            <h3 className="my-3 font-inter font-semibold">
              Abusing Apical Fitness Services.
            </h3>
            <p>
              The flagging system to tell us about any problems or offensive
              content that is being posted. The flagging system is to encourage
              users to work with us so we could collectively keep our services
              and site working properly. Apical Fitness may limit or terminate
              its Services, remove hosted content and take technical and legal
              steps to keep users from using our services if we think that they
              are creating deliberate issues or acting inconsistently with our
              policies. Regardless, Apical Fitness does not accept any liability
              for monitoring Apical Fitness or for unauthorized or unlawful
              content which are posted on Apical Fitness by users whether we
              decide to take any of the above steps, which we may but not
              limited to remove hosted content or keep a user off Apical
              Fitness.
            </p>
            <h3 className="my-3 font-inter font-semibold">
              Global Marketplace.
            </h3>
            <p>
              Without limitation to the rights granted below (see ‘Content’),
              some of Apical Fitness’s features may display your ad on other
              sites, like Google or Yahoo or our classifieds sites in other
              countries. By using the Services, you agree that your ads can be
              displayed on these other sites. The terms for our other sites are
              similar to these terms, but you may be subject to additional laws
              or other restrictions in the countries where your ad is posted.
              When you choose to post your ad to another site, you may be
              responsible for ensuring that it does not violate those policies.
              We may remove your ad if it is flagged on our site, or if we
              believe it violates any law or policy.
            </p>
            <h3 className="my-3 font-inter font-semibold">
              Fees and Services.
            </h3>
            <p>
              Using our Services is generally considered to be free. We may
              charge fees for certain Services. If the service you use charges a
              fee, you will be able to review and accept that charge. Our fees
              are quoted in Canadian Dollars and or U.S. Dollars, and we may
              change them from time to time. We will notify you of changes to
              our fee policy by posting such changes on the site. We may choose
              to temporarily change our fees for promotional events or new
              services; these changes are effective when we announce the
              promotional event or new service. Our fees are non-refundable, and
              you are responsible for paying them when they're due. If you
              don't, we may limit your ability to use the Services. If your
              payment method fails or your account is past due, we may collect
              fees owed using other collection mechanisms. <br />
              Canadian taxes associated with our Services will be collected when
              applicable. You agree to provide accurate address information
              necessary in order for Apical Fitness to be able to comply with
              its obligations under applicable law. To the extent that such
              information is not provided, Apical Fitness will in its discretion
              determine and collect appropriate taxes. <br />
              You are solely responsible to collect and remit any applicable
              taxes resulting from the sale of your items or services listed on
              Apical Fitness. While many features of the Services are available
              across the Apical Fitness websites and their associated mobile
              applications, some features and functionality may vary depending
              on which website and/or which mobile device you use to access the
              Services.
            </p>
            <h3 className="my-3 font-inter font-semibold">Content.</h3>
            <p>
              The Services provided by us, you, and other users (as well as from
              our third party service providers). You agree not to copy, modify,
              resell or distribute the Services, our copyrights or trademarks.
              When you give us content, you are granting us a non-exclusive,
              worldwide, perpetual, irrevocable, royalty-free, sub-licensable
              right to exercise the copyright, publicity, collect and database
              rights to that content. If you believe that your rights have been
              violated, please notify our Customer Support and we will
              investigate the matter. We reserve the right to remove content
              where we have grounds for suspecting the violation of these terms
              or the rights of any other party.
            </p>
            <h3 className="my-3 font-inter font-semibold">
              Reporting Intellectual Property Infringements.
            </h3>
            <p>
              Users may not interfered with, infringed upon, misappropriated, or
              otherwise come into conflict with any Intellectual Property rights
              of third parties. Apical Fitness has not received any charge,
              complaint, claim, demand, or notice alleging any such
              interference, infringement, misappropriation, or violation
              (including any claim that Users must license or refrain from using
              any Intellectual Property rights of any third party). Users agree
              that they will not interfere with, infringe upon, misappropriate,
              or otherwise come into conflict with, any Intellectual Property
              rights of third parties as a result of the continued use of Apical
              Fitness’s Services as conducted on our Term of Use and as
              presently proposed to be conducted.
            </p>
            <h3 className="my-3 font-inter font-semibold">
              Disclaimers and Limitations of Liability.
            </h3>
            <p>
              All services provided by Apical Fitness under this agreement are
              provided on an 'as is' basis without warranty of any kind and
              Apical Fitness makes NO warranties or conditions of any kind,
              express, implied or statutory, related to or arising in any way
              out of THE services. Apical Fitness specifically disclaims any
              implied warranty of merchantability, NON-INFRINGEMENT or fitness
              for a particular purpose. In no event shall Apical Fitness be
              liable for indirect, consequential, incidental, punitive, or
              special damages (including, but not limited to, damages for loss
              of business profits, business interruption, delays, loss of
              business information and the like) arising out of the use, or the
              inability to use, or any defects in SOFTWARE, systems OR services
              pursuant to this agreement, regardless of whether such liability
              is based on breach of contract, tort, strict liability, breach of
              warranties, failure of essential purpose or otherwise and even if
              Apical Fitness has been advised of the possibility of such
              damages. WITHOUT LIMITING THE ABOVE, Apical Fitness’ TOTAL
              CUMULATIVE LIABILITY UNDER THIS AGREEMENT OR ARISING FROM THE
              SERVICES SHALL NOT EXCEED THE TOTAL A MOUNT OF FEES ACTUALLY PAID
              TO Apical Fitness BY THE USER IN THE 6 MONTHS PRIOR TO THE ACTION
              GIVING RISE TO LIABILITY, OR 100 Canadian Dollars UNDER THIS
              AGREEMENT. As used in this Section Apical Fitness includes its
              AFFILIATES, subcontractors, and their respective partners,
              MEMBERS, MANAGERS, officers, directors, shareholders, OWNERS,
              AGENTS and employees. You also agree not to hold Apical Fitness
              responsible for the payment processing of other service providers
              such as 'Third Party Payment Processor'. When making a payment
              through a Third Party Payment Processor, you are bound by that
              Third Party Payment Processor’s applicable terms and conditions,
              and we are not liable for any loss, claims or damages howsoever
              arising in connection with that third party’s services).
              Notification functionality or live messaging system in our
              Services may not occur in real time. Such functionality is subject
              to delays which may or may not be in our control, including
              without limitation, delays or latency due to your physical
              location or your wireless data service provider’s network.
              Accordingly, to the extent legally permitted we expressly disclaim
              all warranties, representations and conditions, express or
              implied, including those of quality, merchantability, merchantable
              quality, durability, fitness for a particular purpose and those
              arising by statute. We are not liable for any loss, whether of
              money (including profit), goodwill, or reputation, or any special,
              indirect, or consequential damages arising out of your use, even
              if you advise us or we could reasonably foresee the possibility of
              any such damage occurring. Some jurisdictions do not allow the
              disclaimer of warranties or exclusion of damages, so such
              disclaimers and exclusions may not apply to you.
            </p>
            <h3 className="my-3 font-inter font-semibold">Indemnification.</h3>
            <p>
              The User agrees to indemnify and hold harmless Apical Fitness and
              each of its directors and officers and each person, if any,
              against any loss, liability, claim, damages or expense ( including
              the reasonable cost of investigating or defending any alleged
              loss, liability, claim, damages, or expense and reasonable counsel
              fees incurred in connection therewith) arising by any reason or by
              any person or other information filed or made public. We reserve
              the right, at our own expense, to assume the exclusive defense and
              control of any matter subject to indemnification by you, but doing
              so will not excuse your indemnity obligations.
            </p>
            <h3 className="my-3 font-inter font-semibold">
              Personal Information.
            </h3>
            <p>
              By using our Services, you agree to the collection, transfer,
              storage and use of your personal information by Apical Fitness for
              any purpose Apical Fitness finds suitable. (the 'data controller')
              on servers located in the United States and in the European Union
              as further described in our Privacy Policy.
            </p>
            <h3 className="my-3 font-inter font-semibold">Reviews.</h3>
            <p>
              You may be asked to leave a review when messaging with other users
              on Apical Fitness. Reviews consist of a 1-5-star rating and a
              brief overview of your experience. Your submitted review, user
              name, profile photo (if available), and the category of the
              seller’s listing will be made public. User reviews should be
              honest and left in good faith, ensuring that both you and the
              other user are depicting your interaction fairly. When leaving a
              review on another user's account, the review should be used to try
              to get a better deal or to tarnish the reputation of a competing
              business. To ensure the integrity of the review system, users are
              not able to edit or remove reviews on their own profile or someone
              else’s. Apical Fitness will also not mediate review-related
              disputes. User reviews reflect the opinions of individual users
              and do not reflect the opinion of Apical Fitness. <br />
              We reserve the right to remove reviews which violate our policies
              or guidelines.
            </p>
            <h3 className="my-3 font-inter font-semibold">General.</h3>
            <p>
              These terms and the other policies posted on the Services
              constitute the entire agreement between us and Apical Fitness,
              superseding any prior agreements. This agreement is governed by
              the laws of the Province of Ontario and the federal laws of Canada
              applicable therein. We both submit to the jurisdiction of the
              courts of the Province of Ontario. This will not affect your
              statutory rights if you are a consumer and applicable consumer law
              requires application of another law for certain topics. If we
              don't enforce any particular provision, we are not waiving our
              right to do so later. If a court strikes down any of these terms,
              the remaining terms will survive. We may automatically assign this
              agreement in our sole discretion in accordance with the notice
              provision below (our assignment to an affiliate will not require
              notice). Except for notices relating to illegal or infringing
              content, your notices to us must be sent by registered mail to:
            </p>
            <address className="font-semibold not-italic">
              Apical Fitness Corp.( Apical Fitness) <br />
              18 King Street East <br />
              Suite 1400, Toronto, Ontario <br />
              M5C 1C4 <br />
              Re: User Inquiries
            </address>
            <p>
              We will send notices to you via the email address you provide, or
              by registered mail. Notices sent by registered mail will be deemed
              received five days following the date of mailing. We may update
              this agreement at any time, with updates taking effect when you
              next use the site or apps or after 30 days from the date of the
              update, whichever is sooner. No other amendment to this agreement
              will be effective unless agreed by both the user and by Apical
              Fitness.
            </p>
          </div>
          <div>
            <h2 className="my-3 font-inter font-semibold ">
              Mobile Devices Terms
            </h2>
            <p>
              If you're accessing the Services from a mobile device using a
              Apical Fitness mobile application hereon known as the
              'Application', the following terms and conditions 'Mobile Devices
              Terms' apply to you in addition to the applicable Mobile Privacy
              and Legal Notice or End User License Agreement, as the case may
              be. Your use of the Application confirms your agreement to these
              Mobile Devices Terms.
            </p>
            <h3 className="my-3 font-inter font-semibold ">Application Use.</h3>
            <p>
              Apical Fitness grants you the right to use the Application
              pursuant to the following restrictions: you may not (a) modify,
              copy, publish, license, sell, or otherwise commercialize this
              Application or any information or software associated with this
              Application; (b) rent, lease or otherwise transfer rights to this
              Application; or (c) use this Application in any manner that could
              impair the Application or interfere with any party's use or
              enjoyment of the Application. You must comply with all applicable
              laws and third party terms of agreement when using the Application
              (e.g. your wireless data service agreement). The Application may
              not contain the same functionality available on the Apical Fitness
              website (and certain functionality may not be available depending
              on which Application and/or mobile device you are using). Your
              download and use of the Application are at your own discretion and
              risk, and you are solely responsible for any damages to your
              hardware device(s) or loss of data that results from the download
              or use of the Application.
            </p>
            <h3 className="my-3 font-inter font-semibold ">
              Intellectual Property – Applications.
            </h3>
            <p>
              Apical Fitness owns, or is the licensee to, all right, title, and
              interest in and to its Applications, including all rights under
              patent, copyright, trade secret, trademark, and any and all other
              proprietary rights, including all applications, renewals,
              extensions, and restorations thereof. You will not modify, adapt,
              translate, prepare derivative works from, decompile,
              reverse-engineer, disassemble, or otherwise attempt to derive
              source code from any Application and you will not remove, obscure,
              or alter Apical Fitness's copyright notice, trademarks or other
              proprietary rights notices affixed to, contained within, or
              accessed in conjunction with or by any Application.
            </p>
            <h3 className="my-3 font-inter font-semibold ">
              Prohibited Countries Policy and Foreign Trade Regulation -
              Applications.
            </h3>
            <p>
              The Application or its underlying technology may not be downloaded
              to or exported or re-exported: (a) into (or to a resident or
              national of) any country subject to United States embargo; (b) to
              anyone on the U.S. Department of the Treasury’s list of Specially
              Designated Nationals or on the U.S. Department of Commerce’s
              Denied Party or Entity List; and (c) to any prohibited country,
              person, end-user, or entity specified by U.S. export laws. When
              using the Application, you are responsible for complying with
              trade regulations and both foreign and domestic laws (e.g., you
              are not located in a country that is subject to a U.S. government
              embargo, or that has been designated by the U.S. government as a
              'terrorist supporting' country, and you are not listed on any U.S.
              government list of prohibited or restricted parties).
            </p>
            <h3 className="my-3 font-inter font-semibold ">
              Additional Terms.
            </h3>
            <p>
              Additional terms and conditions that apply to you based on the
              mobile device the Application is installed on:
            </p>
            <div>
              <p className="text-xl mt-3">iOS - Apple.</p>
              <ul className="list-disc list-inside">
                <li>
                  These terms are an agreement between you and Apical Fitness,
                  and not with Apple. Apple is not responsible for the
                  Application and the content thereof.
                </li>
                <li>
                  Apical Fitness grant’s you the right to use the Application
                  only on an iOS product that you own or control and as
                  permitted by the Usage Rules set forth in Apple’s then-current
                  App Store Media Services Terms and Conditions.
                </li>
                <li>
                  Apple has no obligation whatsoever to furnish any maintenance
                  and support services with respect to the Application.
                </li>
                <li>
                  Apple is not responsible for the investigation, defense,
                  settlement, and discharge of any third party intellectual
                  property infringement claim.
                </li>
                <li>
                  Apple is not responsible for addressing any claims by you or
                  any third party relating to the Application or your possession
                  and/or use of the Application, including but not limited to:
                  (a) product liability claims; (b) any claim that the
                  Application fails to conform to any applicable legal or
                  regulatory requirement; and (c) claims arising under consumer
                  protection or similar legislation.
                </li>
                <li>
                  In the event of any failure of the Application to conform to
                  any applicable warranty, you may notify Apple, and Apple may
                  refund the purchase price, if applicable, for the Application
                  to you; and to the maximum extent permitted by applicable law,
                  Apple will have no other warranty obligation whatsoever with
                  respect to the Application.
                </li>
                <li>
                  Apple and Apple's subsidiaries are third party beneficiaries
                  of these terms, and, upon your acceptance, Apple as a third
                  party beneficiary thereof will have the right (and will be
                  deemed to have accepted the right) to enforce these terms
                  against you.
                </li>
              </ul>
            </div>
            <div>
              <p className="text-xl mt-3">Android - Google</p>
              <ul className="list-disc list-inside">
                <li>
                  These terms are an agreement between you and Apical Fitness,
                  and not with Google. Google is not responsible for the
                  Application and the content thereof.
                </li>
                <li>
                  Your use of the Application must comply with Google’s
                  then-current Google Play Store Terms of Service;
                </li>
                <li>
                  Google is only a provider of the Google Play Store where you
                  obtained the Application;
                </li>
                <li>
                  Google has no obligation or liability to you with respect to
                  the Application or these terms;
                </li>
                <li>
                  You acknowledge and agree that Google is a third-party
                  beneficiary to these terms as it relates to the Application.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {password && matchPassword && validMatchPassword && !isAuth ? (
          <>
            <form className="my-2 flex gap-4" id="privacy-policy">
              <input
                type="checkbox"
                name="privacy_check"
                id="privacy_check"
                className="appearance-none privacy-checkbox"
                onChange={() => setIsChecked(!isChecked)}
              />
              <label
                htmlFor="privacy_check"
                className="p-2 relative before:absolute before:z-10 before:text-green after:absolute after:content[''] after:border-2 after:border-green after:w-5 after:h-5 after:bg-light after:rounded cursor-pointer"
              >
                You agree to our Terms of Use and that you are over 18 and
                acknowledge the Privacy Policy. You agree that your membership
                will begin immediately, and that you will not be able to
                withdraw from the contract and receive a refund. You may cancel
                at any time to avoid future charges.
              </label>
            </form>
            {successMsg ? (
              <Message msgType="success" message={successMsg} />
            ) : null}
            {isChecked ? (
              <>
                <PayPalButtons
                  style={{
                    label: "pay",
                    tagline: false,
                  }}
                  createSubscription={createSubscription}
                  onApprove={onApprove}
                  onError={onError}
                />
              </>
            ) : null}
            <ToastContainer />
          </>
        ) : null}
      </section>
    </main>
  );
};

export default TermsOfUse;
