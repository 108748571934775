import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toDataURL } from "../utils/Helpers";
import PuffLoader from "react-spinners/PuffLoader";
// Icons
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
// Images
import avatarPic from "../assets/images/user1.png";

const LoginContext = createContext();

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;

const LoginProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [passwordInputIcon, setPasswordinputIcon] = useState(<BsEyeFill />);
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const resetToken = searchParams.get("token");
  const [isShowRecoverBtn, setIsShowRecoverBtn] = useState(true);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState({});
  const [imgSrc, setImgSrc] = useState(avatarPic);
  const [isAuth, setIsAuth] = useState(false);

  const showHidePassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
      setPasswordinputIcon(<BsEyeSlashFill />);
    } else {
      setPasswordInputType("password");
      setPasswordinputIcon(<BsEyeFill />);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_TOKEN,
        JSON.stringify({
          username: email,
          password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );
      const data = response.data;
      if (response.status === 200) {
        setAuthTokens(data);
        localStorage.setItem("authTokens", JSON.stringify(data));
        getUser(data.access);
        setEmail("");
        setPassword("");
        navigate("/", { replace: true });
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response");
        setShowMsg(true);
      } else if (
        err?.response.status === 400 &&
        err.response.data.detail[0] === "Incorrect email."
      ) {
        setErrMsg("Incorrect Email, please try again.");
        setShowMsg(true);
      } else if (
        err?.response.status === 400 &&
        err.response.data.detail[0] === "Incorrect email or password."
      ) {
        setErrMsg("Incorrect Email or Password, please try again.");
        setShowMsg(true);
      } else {
        logoutUser();
        setErrMsg("Login failed!");
        setShowMsg(true);
      }
    }
  };

  const logoutUser = useCallback(() => {
    setLoading(false);
    setIsAuth(false);
    setAuthTokens(null);
    setUser({});
    localStorage.removeItem("authTokens");
    navigate("/login", { replace: true });
  }, [navigate]);

  const updateToken = useCallback(async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_REFRESH,
        JSON.stringify({ refresh: authTokens?.refresh }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );
      const data = response.data;
      if (response.status === 200) {
        localStorage.setItem("authTokens", JSON.stringify(data));
      }
    } catch (err) {
      if (!err.response) {
        alert("No Server Response");
      } else if (err?.response.status === 401) {
        logoutUser();
      }
    }

    if (loading) {
      setLoading(false);
    }
  }, [authTokens?.refresh, loading, logoutUser]);

  const getUser = useCallback(
    async (accessToken) => {
      try {
        const response = await axios.get(process.env.REACT_APP_ME, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "App-Type": "Web",
          },
        });
        const data = response.data;
        if (response.status === 200) {
          setUser(data);
          setImgSrc((currentImg) =>
            data.image ? `data:image/png;base64,${data.image}` : currentImg
          );
          setLoading(false);
          setIsAuth(true);
        }
      } catch (err) {
        if (!err.response) {
          alert("No Server Response");
        } else if (err?.response.status === 401) {
          updateToken();
        } else {
          logoutUser();
        }
      }
    },
    [logoutUser, updateToken]
  );

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_FORGET_PWD,
        JSON.stringify({
          email: accountEmail,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 200) {
        setSuccessMsg(
          "We have sent a password recovery instruction to your email, please check it! (Check Spam if you don't find it)"
        );
        setAccountEmail("");
        setShowMsg(true);
        setIsShowRecoverBtn(false);
      }
    } catch (err) {
      console.log("CLUTCH", err?.response.status);
      if (!err.response) {
        setErrMsg("No Server Response");
        setShowMsg(true);
      } else if (err?.response.status === 422) {
        setErrMsg("Incorrect Email, please try again.");
        setShowMsg(true);
      } else {
        setErrMsg("Operation Failed");
        setShowMsg(true);
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const v = PWD_REGEX.test(newPassword);
    if (!v) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_RENEW_PWD,
        JSON.stringify({
          token: resetToken,
          password: newPassword,
          password_confirmation: repeatNewPassword,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );

      if (response.status === 200) {
        navigate("/login", { replace: true });
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response!");
        setShowMsg(true);
      } else {
        setErrMsg("Operation Failed!");
        setShowMsg(true);
      }
    }
  };

  useEffect(() => {
    if (authTokens) {
      getUser(authTokens.access);
    } else {
      setLoading(false);
    }
  }, [authTokens, getUser]);

  useEffect(() => {
    if (loading && isAuth) {
      updateToken();
    }

    let oneDay = 1000 * 60 * 60 * 24 - 5000 * 60;

    let interval = setInterval(() => {
      if (authTokens && isAuth) {
        updateToken();
      }
    }, oneDay);
    return () => clearInterval(interval);
  }, [authTokens, loading, updateToken, isAuth]);

  useEffect(() => {
    toDataURL(avatarPic).then((dataUrl) => setImgSrc(dataUrl));
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMsg(false);
      setSuccessMsg("");
      setErrMsg("");
    }, 8000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showMsg]);

  useEffect(() => {
    !showMsg && setIsShowRecoverBtn(true);
  }, [showMsg]);

  return (
    <LoginContext.Provider
      value={{
        email,
        setEmail,
        accountEmail,
        setAccountEmail,
        password,
        setPassword,
        newPassword,
        setNewPassword,
        repeatNewPassword,
        setRepeatNewPassword,
        passwordInputType,
        setPasswordInputType,
        passwordInputIcon,
        setPasswordinputIcon,
        showHidePassword,
        handleSubmit,
        user,
        imgSrc,
        setImgSrc,
        authTokens,
        isAuth,
        logoutUser,
        errMsg,
        handleForgotPassword,
        handleResetPassword,
        successMsg,
        isShowRecoverBtn,
        getUser,
      }}
    >
      {loading ? (
        <div className="h-screen w-screen flex items-center justify-center">
          <PuffLoader color="#25AB75" />
        </div>
      ) : (
        children
      )}
    </LoginContext.Provider>
  );
};

function LoginConsumer() {
  return useContext(LoginContext);
}

export { LoginProvider, LoginConsumer };
