import ReactDOM from "react-dom";
import Plan from "./Plan";

const ChoosePlan = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div
      onClick={onClose}
      className="absolute top-0 left-0 min-h-screen w-full z-50 lg:p-32 lg:pb-64 bg-dark bg-opacity-70 text-dark"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-3 relative text-center py-12 lg:px-12 bg-white rounded"
      >
        <button
          className="absolute top-7 right-7 text-3xl text-dark-600 font-bold"
          onClick={onClose}
        >
          X
        </button>
        <div>
          <h2 className="text-3xl font-bold uppercase mb-4">Change plan</h2>
          <p className="text-md font-bold lg:w-1/3 mx-auto">
            We do not refund money for the previous plan, but we give a discount
            of -10% on the new plan
          </p>
        </div>
        <div className="flex justify-center items-center flex-wrap gap-2 text-white">
          <Plan
            planName="bronze"
            isSelectPlanBtn={true}
            freez={true}
            discover={true}
          />
          <Plan
            planName="silver"
            isSelectPlanBtn={true}
            freez={true}
            discover={true}
          />
          <Plan
            planName="gold"
            isSelectPlanBtn={true}
            freez={true}
            discover={true}
          />
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default ChoosePlan;
