import { Link } from "react-router-dom";
import bgFaq from "../assets/images/bg_faqs.jpg";
import Container from "./Container";

function FAQSection() {
  return (
    <section className="py-20">
      <Container>
        <div className="flex gap-8">
          <div className="w-2/3 h-full rounded overflow-hidden hidden lg:block">
            <img src={bgFaq} alt="barbell" className="w-full object-cover" />
          </div>
          <div className="text-yellow flex flex-col gap-10">
            {faqsArr.map((faq, index) => {
              return (
                <div key={index} className="flex flex-col gap-2">
                  <p className="text-3xl font-bold">{faq.question}</p>
                  <p className="">{faq.answer}</p>
                </div>
              );
            })}
            <Link to="/faqs" className="underline text-light">
              ALL FAQ
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default FAQSection;

const faqsArr = [
  {
    question: "How can I become a member?",
    answer:
      "You can easily become a member by selecting and purchasing one of the membership packages featured on our website.",
  },
  {
    question: "How long is the plan period?",
    answer: "The subscription period is every two weeks.",
  },
  {
    question: "How do I download the mobile app?",
    answer:
      "You can download our mobile app from either the Apple Store or the Play Store.",
  },
];
