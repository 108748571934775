import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { LoginConsumer } from "./LoginContext";

const PartnersContext = createContext();

const PartnersProvider = ({ children }) => {
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [filteredPartnersBySelectedPlan, setFilteredPartnersBySelectedPlan] =
    useState([]);
  const [sport, setSport] = useState("");
  const [location, setLocation] = useState("");
  const [age, setAge] = useState("");
  const [subscribedPlan, setSubscribedPlan] = useState("");
  const [cities, setCities] = useState([]);
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user, isAuth } = LoginConsumer();
  let selectedPlan = isAuth && user.plan.toLowerCase();

  const resetFilter = () => {
    setFilteredPartners([]);
    setSport("");
    setLocation("");
    setAge("");
    setSubscribedPlan("");
  };

  const getPartners = async () => {
    const response = await axios.get(process.env.REACT_APP_DISCOVER_PARTNERS, {
      headers: {
        "App-Type": "Web",
      },
    });
    const data = response.data;
    if (response.status === 200) {
      setPartners(data);
      setLoading(false);
    }
  };

  const getCities = async () => {
    const response = await axios.get(process.env.REACT_APP_CITIES, {
      headers: {
        "App-Type": "Web",
      },
    });
    const data = response.data;
    if (response.status === 200) {
      const sortedCities = data.sort((a, b) => a.name.localeCompare(b.name));
      setCities(sortedCities);
    }
  };

  const getSports = async () => {
    const response = await axios.get(process.env.REACT_APP_SPORTS, {
      headers: {
        "App-Type": "Web",
      },
    });
    const data = response.data;
    if (response.status === 200) {
      const sortedSports = data.sort((a, b) => a.name.localeCompare(b.name));
      setSports(sortedSports);
    }
  };

  const filterPartners = useCallback(() => {
    let tempPartners = [...partners];
    // Filter by Sport Categorie
    if (sport && sport !== "all") {
      tempPartners = tempPartners.filter((partner) =>
        partner.sports.map((item) => item.name).includes(sport)
      );
    }
    // Filter by City
    if (location && location !== "all") {
      tempPartners = tempPartners.filter(
        (partner) => partner.city === location
      );
    }
    // Filter by Age
    if (age && age !== "all") {
      tempPartners = tempPartners.filter((partner) =>
        partner.age_groups.map((item) => item).includes(age)
      );
    }
    // Filter by Subscribed Plan
    if (subscribedPlan && subscribedPlan !== "all") {
      tempPartners = tempPartners.filter((partner) =>
        partner.subscribed_plans.map((item) => item).includes(subscribedPlan)
      );
    }
    setFilteredPartners(tempPartners);
  }, [partners, sport, location, age, subscribedPlan]);

  const filterPartnersBySelectedPlan = useCallback(() => {
    let tempPartners = [...filteredPartners];
    tempPartners = tempPartners.filter((partner) =>
      partner.subscribed_plans.map((item) => item).includes(selectedPlan)
    );
    setFilteredPartnersBySelectedPlan(tempPartners);
  }, [filteredPartners, selectedPlan]);

  useEffect(() => {
    getPartners();
    getCities();
    getSports();
  }, []);

  useEffect(() => {
    filterPartners();
  }, [filterPartners]);

  useEffect(() => {
    isAuth && filterPartnersBySelectedPlan();
  }, [filterPartnersBySelectedPlan, isAuth]);

  return (
    <PartnersContext.Provider
      value={{
        partners,
        filteredPartners,
        filteredPartnersBySelectedPlan,
        loading,
        sport,
        setSport,
        location,
        setLocation,
        age,
        setAge,
        subscribedPlan,
        setSubscribedPlan,
        sports,
        cities,
        resetFilter,
      }}
    >
      {children}
    </PartnersContext.Provider>
  );
};

function PartnersConsumer() {
  return useContext(PartnersContext);
}

export { PartnersProvider, PartnersConsumer };
