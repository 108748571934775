import { useEffect } from "react";
import Plan from "./Plan";
import ChoosePlan from "./ChoosePlan";
import { ManagePlansConsumer } from "../../contexts/ManagPlansContext";
import FreezPlan from "../popups/FreezPlan";

const ChangeMembership = () => {
  const {
    selectedPlan,
    isPlansModalOpen,
    onPlansModalClose,
    isFreezModalOpen,
    onFreezModalClose,
  } = ManagePlansConsumer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="border-y-4 lg:border-y-0 lg:border-x-4 border-green max-w-xl min-h-screen bg-dark-600">
      <div>
        <div className="p-6 lg:p-20 lg:pb-8 w-fit mx-auto text-center">
          <h3 className="text-3xl text-center font-semibold uppercase mb-3">
            MANAGE MEMBERSHIP
          </h3>
          <p className="mb-6 text-lg">
            You are currently on {selectedPlan} plan
          </p>

          <Plan planName={selectedPlan} notDiscover={true} />
        </div>

        {selectedPlan === "bronze" || selectedPlan === "silver" ? (
          <p className="mb-6 bg-yellow text-dark text-center py-1 font-bold">
            Get -10% Discount by changing plan
          </p>
        ) : null}

        <div className="p-6 lg:px-20 text-center">
          <p>
            We do not refund money for the previous plan, but we give a discount
            of -10% if you upgrade the plan.
          </p>
        </div>
      </div>
      <ChoosePlan isOpen={isPlansModalOpen} onClose={onPlansModalClose} />
      <FreezPlan isOpen={isFreezModalOpen} onClose={onFreezModalClose} />
    </div>
  );
};

export default ChangeMembership;
