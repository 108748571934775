import { PartnersConsumer } from "../../contexts/PartnersContext";
import { LoginConsumer } from "../../contexts/LoginContext";
import { FcClearFilters } from "react-icons/fc";

const PartnerFilter = ({ mode }) => {
  const {
    sport,
    setSport,
    location,
    setLocation,
    age,
    setAge,
    subscribedPlan,
    setSubscribedPlan,
    sports,
    cities,
    resetFilter,
  } = PartnersConsumer();
  const { isAuth } = LoginConsumer();

  return (
    <>
      <form className="flex flex-wrap justify-between lg:justify-start items-center gap-4 lg:gap-4">
        <select
          id="sport_category"
          className={`${
            mode === "light"
              ? "text-blue-light bg-white border border-light-400 py-1 px-2 h-12 rounded flex-1"
              : "text-gray-600 bg-white border py-1 px-2 h-12 rounded flex-1"
          } text-xs w-[150px] max-h-[40px]`}
          value={sport || "all"}
          onChange={(e) => setSport(e.target.value)}
        >
          <option value="none" disabled>
            Sport Category
          </option>
          <option value="all">All Sport Categories</option>
          {sports.map((sport) => (
            <option key={sport.id} value={sport.name}>
              {sport.name}
            </option>
          ))}
        </select>
        <select
          id="location"
          className={`${
            mode === "light"
              ? "text-blue-light bg-white border border-light-400 py-1 px-2 h-12 rounded flex-1"
              : "text-gray-600 bg-white border py-1 px-2 h-12 rounded flex-1"
          } text-xs w-[150px] max-h-[40px]`}
          value={location || "all"}
          onChange={(e) => setLocation(e.target.value)}
        >
          <option value="none" disabled>
            Location
          </option>
          <option value="all">All Locations</option>
          {cities.map((city) => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>
        <select
          id="age"
          className={`${
            mode === "light"
              ? "text-blue-light bg-white border border-light-400 py-1 px-2 h-12 rounded flex-1"
              : "text-gray-600 bg-white border py-1 px-2 h-12 rounded flex-1"
          } text-xs w-[100px] max-h-[40px]`}
          value={age || "all"}
          onChange={(e) => setAge(e.target.value)}
        >
          <option value="none" disabled>
            Age
          </option>
          <option value="all">All Ages</option>
          <option value="young">Young</option>
          <option value="adult">Adult</option>
          <option value="senior">Senior</option>
        </select>
        {isAuth ? (
          <select
            id="subscribed_plan"
            className={`${
              mode === "light"
                ? "text-blue-light bg-white border border-light-400 py-1 px-2 h-12 rounded flex-1"
                : "text-gray-600 bg-white border py-1 px-2 h-12 rounded flex-1"
            } text-xs w-[150px] max-h-[40px]`}
            value={subscribedPlan || "all"}
            onChange={(e) => setSubscribedPlan(e.target.value)}
          >
            <option value="none" disabled>
              Subscribed Plan
            </option>
            <option value="all">All Plans</option>
            <option value="gold">Gold</option>
            <option value="silver">Silver</option>
            <option value="bronze">Bronze</option>
          </select>
        ) : null}
        <FcClearFilters
          size="2rem"
          className="cursor-pointer"
          title="Clear Filter"
          onClick={resetFilter}
        />
      </form>
    </>
  );
};

export default PartnerFilter;
