import bgBestSelection from "../assets/images/bg_best_selection.png";
import Container from "./Container";

function BestSelection() {
  return (
    <section id="about" className="relative py-28 bg-black overflow-hidden">
      <img
        src={bgBestSelection}
        alt="background"
        className="absolute right-0 h-full object-cover"
      />
      <Container className="z-10 flex flex-col justify-center">
        <p className="uppercase text-4xl md:text-7xl font-bold w-full">
          Best <span className="text-yellow">Selection</span>
        </p>
        <p className="mt-8 md:max-w-2xl text-lg md:text-xl">
          Apical Fitness is your ultimate gateway to the city's premier fitness
          facilities, all wrapped up in a single membership. With Apical
          Fitness, the possibilities are endless. You can embark on a journey of
          discovering new sports and activities that cater to your interests and
          preferences. From yoga to high-intensity workouts, and from indoor
          environments to the great outdoors, the choice is yours. What's more,
          you're not bound by any strict schedules or fixed locations. Join us
          today and experience the city's fitness scene like never before!
        </p>
      </Container>
    </section>
  );
}

export default BestSelection;
