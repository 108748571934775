import { useCallback, useRef } from "react";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, EffectFade } from "swiper";
import SwiperCore from "swiper/core";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
// Icons
import { IoChevronForwardCircleSharp } from "react-icons/io5";
// Images
import rundofaseImg2 from "../../../assets/images/rundofase_2.jpg";
import rundofaseImg3 from "../../../assets/images/rundofase_3.jpg";

SwiperCore.use([Navigation]);

const PartnerDetailCarousel = ({ images, name }) => {
  const sliderRef = useRef(null);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="mt-8 relative">
      <Swiper
        slidesPerView={images.length > 2 ? 2.5 : 2}
        spaceBetween={10}
        speed={500}
        navigation={{
          nextEl: "nextSlide",
          prevEl: null,
        }}
        modules={[Navigation, EffectFade]}
        loop={images.length > 3}
        effect
        className="mySwiper md:mr-20"
        style={{ position: "unset" }}
        ref={sliderRef}
      >
        {images.length ? (
          images.map((image) => {
            return (
              <SwiperSlide key={image.id}>
                <div className="w-full h-80 rounded-lg overflow-hidden">
                  <img
                    src={image.image}
                    alt={`${name} gym`}
                    className="w-full h-full object-cover"
                  />
                </div>
              </SwiperSlide>
            );
          })
        ) : (
          <>
            <SwiperSlide>
              <img
                src={rundofaseImg2}
                alt="Rundofase gym"
                className="max-h-[600px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={rundofaseImg3}
                alt="Rundofase gym"
                className="max-h-[600px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={rundofaseImg3}
                alt="Rundofase gym"
                className="max-h-[600px] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={rundofaseImg2}
                alt="Rundofase gym"
                className="max-h-[600px] w-full"
              />
            </SwiperSlide>
          </>
        )}

        {images.length > 2 ? (
          <span
            className="hidden md:block text-green text-4xl cursor-pointer absolute top-1/2 -translate-y-1/2 right-4 nextSlide"
            onClick={handleNext}
          >
            <IoChevronForwardCircleSharp />
          </span>
        ) : null}
      </Swiper>
    </div>
  );
};

export default PartnerDetailCarousel;
