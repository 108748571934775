import Plan from "./Plan";
import linesPic from "../assets/images/topographic_3.svg";

const SelectPlan = () => {
  return (
    <section className="py-12 relative" id="select-plan">
      <img
        src={linesPic}
        alt="Lines background"
        className="absolute right-0 top-0 max-h-full lg:w-1/2"
      />
      <div className="max-w-7xl container mx-auto px-4 sm:px-6 lg:px-12 xl:px-0 relative z-10">
        <div className="text-center mb-12">
          <h2 className="font-bold text-3xl md:text-6xl text-center mb-6">
            Select Plan to become a Member
          </h2>
          <p className="lg:text-xl">
            Pay one subscription and visit different gyms. <br /> Use our app as
            a ticket to the gyms
          </p>
        </div>
        <div className="flex flex-wrap gap-5 justify-center items-center">
          <Plan joinNowBtn={true} planName="bronze" discover="true" />
          <Plan joinNowBtn={true} planName="silver" discover="true" />
          <Plan joinNowBtn={true} planName="gold" discover="true" />
        </div>
      </div>
    </section>
  );
};

export default SelectPlan;
