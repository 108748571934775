import { useEffect } from "react";
import { AccountSettingsConsumer } from "../../contexts/AccountSettingsContext";
import SettingsContainer from "./SettingsContainer";
import Message from "../Message";

const ForgotPassword = () => {
  const {
    accountEmail,
    setAccountEmail,
    handleForgotPassword,
    errMsg,
    successMsg,
  } = AccountSettingsConsumer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SettingsContainer>
      <div>
        <h3 className="text-3xl text-center lg:text-left font-semibold uppercase mb-3">
          FORGOT PASSWORD
        </h3>
        <p className="mb-6">Enter your account email to recover a password</p>
        <form className="mt-5" onSubmit={handleForgotPassword}>
          <div className="mb-6 relative w-full">
            <input
              type="email"
              name="account_email"
              placeholder="Account email"
              value={accountEmail}
              required
              onChange={(e) => setAccountEmail(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
          </div>

          <Message msgType="success" message={successMsg} text="left" />
          <Message msgType="error" message={errMsg} text="left" />

          <button
            type="submit"
            className="p-3 bg-green text-white rounded block w-fit mx-auto lg:ml-0"
          >
            RECOVER PASSWORD
          </button>
        </form>
      </div>
    </SettingsContainer>
  );
};

export default ForgotPassword;
