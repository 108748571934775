import { useEffect } from "react";
const PrivacyPolicyPartner = () => {
  return (
    <main className="py-12 min-h-screen privacy-policy relative">
      <section>
        <h1>PRIVACY POLICY - Apical Fitness </h1>
        <p>Last updated: Jun 01, 2023</p>
        <div>
          <p>
            This privacy notice for Apical Fitness Corp. ("Company" "we," "us,"
            or "our"), describes how and why we might collect, store, use,
            and/or share ("process") your information when you use our services
            ("Services"), such as when you:
          </p>
          <br />
          <p>
            • Visit our website at https://www.ApicalFitness.com/, or any
            website of ours that links to this privacy notice <br />• Download
            and use our mobile application (Apical Fitness Partners), or any
            other application of ours that links to this privacy notice <br />•
            Engage with us in other related ways, including any sales,
            marketing, or events <br />
          </p>
          <p>
            Questions or concerns? Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with
            our policies and practices, please do not use our Services. If you
            still have any questions or concerns, please contact us at
            info@apicalfitness.com .
          </p>
          <h3 className="my-3 font-inter font-semibold">
            SUMMARY OF KEY POINTS
          </h3>
          <p>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by using our
            table of contents below to find the section you are looking for.
          </p>
          <p>
            What personal information do we process? When you visit, use, or
            navigate our Services, we may process personal information depending
            on how you interact with Apical Fitness Corp. and the Services, the
            choices you make, and the products and features you use. Learn more
            about personal information you disclose to us.
          </p>
          <p>
            <span className="font-bold">
              Do we process any sensitive personal information?
            </span>
            We do not process sensitive personal information.
          </p>
          <p>
            <span className="font-bold">
              Do we receive any information from third parties?
            </span>
            We do not receive any information from third parties.
          </p>
          <p>
            <span className="font-bold">
              How do we process your information?
            </span>
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so.
          </p>
          <p>
            <span className="font-bold">
              In what situations and with which parties do we share personal
              information?
            </span>
            We may share information in specific situations and with specific
            third parties. Learn more about when and with whom we share your
            personal information.
          </p>
          <p>
            <span className="font-bold">
              How do we keep your information safe?
            </span>
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Learn more about how we
            keep your information safe.
          </p>
          <p>
            <span className="font-bold">What are your rights?</span> Depending
            on where you are located geographically, the applicable privacy law
            may mean you have certain rights regarding your personal
            information. Learn more about your privacy rights.
          </p>
          <p>
            <span className="font-bold">How do you exercise your rights?</span>
            The easiest way to exercise your rights is by submitting a data
            subject access request, or by contacting us. We will consider and
            act upon any request in accordance with applicable data protection
            laws.
          </p>
          <p>
            Want to learn more about what Apical Fitness Corp. does with any
            information we collect? Review the privacy notice in full.
          </p>

          <h3 className="my-3 font-inter font-semibold">TABLE OF CONTENTS</h3>
          <ol className="list-decimal list-inside">
            <li>WHAT INFORMATION DO WE COLLECT?</li>
            <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
            <li>
              WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </li>
            <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
            <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <li>DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
            <li>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </li>
          </ol>
          <h3 className="my-3 font-inter font-semibold">
            1. WHAT INFORMATION DO WE COLLECT?
          </h3>
          <p>
            In Short: We collect personal information that You provide to us.
            <br />
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us
            <br />
            Personal Information Provided by You. The personal information that
            we collect depends on the context of your interactions with us and
            the Services, the choices you make.
            <br />
            The personal information we collect may include the following:
            <ul className="list-inside list-disc">
              <li>Email addresses</li>
              <li>Passwords</li>
            </ul>
          </p>
          <h4 className="my-3 font-inter font-semibold">
            Sensitive Information
          </h4>
          <p>
            When necessary, with your consent or as otherwise permitted by
            applicable law, we process the following categories of sensitive
            information: <br />
            Payment Data. We may collect data necessary to process your payment
            if you make purchases, such as your payment instrument number, and
            the security code associated with your payment instrument. All
            payment data is stored by http://www.PayPal.com. You may find their
            privacy notice link(s) here:
            http://www.paypal.com/webapps/mpp/privacy-ul
          </p>
          <p>
            <span className="font-bold">Application Data</span>: If you use our
            application(s), we also may collect the following information if you
            choose to provide us with access or permission:
          </p>
          <p>
            - Mobile Device Access. We may request access or permission to
            certain features from your mobile device, including your mobile
            device's camera, and other features. If you wish to change our
            access or permissions, you may do so in your device's settings.
          </p>
          <p>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes. <br />
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h4 className="my-3 font-inter font-semibold">
            Information automatically collected
          </h4>
          <p>
            In Short: Some information-such as your Internet Protocol (IP)
            address and/or browser and device characteristics-is collected
            automatically when you visit our Services. <br />
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
            <br />
            Like many businesses, we also collect information through cookies
            and similar technologies. The information we collect includes
            <p>
              - Device Data. We collect device data such as information about
              your computer, phone, tablet, or other device you use to access
              the Services. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model, internet service provider and/or mobile
              camera, operating system, and system configuration information.
            </p>
          </p>
          <h3 className="my-3 font-inter font-semibold">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h3>
          <p>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent. <br />
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <ul className="list-inside list-disc">
            <li>
              To deliver and facilitate delivery of services to the user. We may
              process your information to provide you with the requested
              service.
            </li>
            <li>
              To enable user-to-user communications. We may process your
              information if you choose to use any of our offerings that allow
              for communication with another user.
            </li>
            <li>
              To identify usage trends. We may process information about how you
              use our Services to better understand how they are being used to
              we can improve them.
            </li>
          </ul>

          <h3 className="my-3 font-inter font-semibold">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </h3>
          <p>
            In Short: We only process your personal information when we believe
            it is necessary and we have a valid legal reason (e, legal basis) to
            do so under applicable law, like with your consent, to comply with
            laws, to provide you with services to enter into or fulfill our
            contractual obligations, to protect your rights, or to fulfill our
            legitimate business interests.
          </p>
          <h4 className="my-3 font-inter font-semibold italic">
            If you are located in Canada, this section applies to you.
          </h4>
          <p>
            We may process your information if you have given us specific
            permission (i.e., express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e., implied consent) You can withdraw your consent at
            any time.
          </p>
          <br />
          <p>
            In some exceptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
          </p>
          <ul className="list-inside list-disc">
            <li>
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way.
            </li>
            <li>For investigations and fraud detection and prevention.</li>
            <li>
              For business transactions provided certain conditions are met.
            </li>
            <li>
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </li>
            <li>
              For identifying injured, m. or deceased persons and communicating
              with next of kin
            </li>
            <li>
              if we have reasonable grounds to believe an individual has been,
              is, or may be victim of financial abuse
            </li>
            <li>
              if it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a breach of an agreement or a contravention of
              the laws of Canada or a province.
            </li>
            <li>
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records.
            </li>
            <li>
              If it was produced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced.
            </li>
            <li>
              If the collection is solely for journalistic, artistic, or
              literary purposes.
            </li>
            <li>
              If the information is publicly available and is specified by the
              regulations
            </li>
          </ul>

          <h3 className="my-3 font-inter font-semibold">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h3>
          <p>
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
            <br />
            We may need to share your personal information in the following
            situations:
            <p>
              - Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of any merger, sale of
              company assets, financing, or acquisition a portion of our
              business to another company
            </p>
          </p>
          <h3 className="my-3 font-inter font-semibold">
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h3>
          <p>
            In Short: We keep your information for as long as necessary to
            fulfil the purposes outlined in this privacy notice unless otherwise
            required by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than twelve (12) months past the termination of the user's account.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <h3 className="my-3 font-inter font-semibold">
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </h3>
          <p>
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your Information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <h3 className="my-3 font-inter font-semibold">
            7. DO WE COLLECT INFORMATION FROM MINORS?
          </h3>
          <p>
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent's use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records, if you
            become aware of any data we may have collected from children under
            age 18, please contact us at info@apicalfitness.com.
          </p>
          <h3 className="my-3 font-inter font-semibold">
            8. WHAT ARE YOUR PRIVACY RIGHTS?
          </h3>
          <p>
            In Short: in some regions, such as Canada, you have rights that
            allow you greater access to and control over your personal
            information. You may review, change, or terminate your account at
            any time.
          </p>
          <p>
            In some regions (like Canada), you have certain rights under
            applicable data protection laws. These may include the right () to
            request access and obtain a copy of your personal information, (i)
            to request rectification or erasure. (n) to restrict the processing
            of your personal information; and (v) if applicable, to data
            portability in certain circumstances, you may also have the right to
            object to the processing of your personal information. You can make
            such a request by contacting us by using the contact details
            provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
            below.
          </p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your Member State data protection authority or
            UK data protection authority.
          </p>
          <p>
            If you are located in Switzerland, you may contact the Federal Data
            Protection and Information Commissioner.
          </p>
          <p>
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section "HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE? Below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal Information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at info@apicalfitness.com.
          </p>
          <h3 className="my-3 font-inter font-semibold">
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h3>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track (DNT) feature or setting you can
            activate to signal your privacy preference not to have data about
            your online browsing activities monitored and collected. At this
            stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online if a
            standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <h3 className="my-3 font-inter font-semibold">
            10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </h3>
          <p>
            In Short: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </p>
          <p>
            California Civil Code Section 1798 83, also known as the "Shine The
            Light law”, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year if
            you are a California resident and would like to make such a request
            please submit your request in willing to us using the contact
            information provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc).
          </p>
          <h3 className="my-3 font-inter font-semibold">CCPA Privacy Notice</h3>
          <p>The California Code of Regulations defines a "resident" as</p>
          <p>
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </p>
          <p>
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </p>
          <p>All other individuals are defined as "non-residents".</p>
          <p>
            If this definition of "resident applies to you, we must adhere to
            certain rights and obligations regarding your personal Information.
          </p>
          <h3 className="my-3 font-inter font-semibold">
            What categories of personal information do we collect?
          </h3>
          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A. identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number unique personal identifier,
                  online identifier, Internet Protocol address, email address,
                  and account name.
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number unique personal identifier,
                  online identifier, Internet Protocol address, email address,
                  and account name.
                </td>
                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or Federal Law
                </td>
                <td>Gender and date of birth</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  H Audio, electronic visual, thermal, offactory, or similar
                </td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our Services
                  at a business level or job title. work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>J. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from other personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual's preferences and characteristics
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>L. Sensitive Personal information</td>
                <td>Account login information</td>
                <td>NO</td>
              </tr>
            </tbody>
          </table>
          <p>
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <ul className="list-inside list-disc">
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <h4 className="my-3 font-inter font-semibold">
            How do we use and share your personal information?
          </h4>
          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice. You may contact us by email at
            info@apicalfitness.com, or by referring to the contact details at
            the bottom of this document. <br />
            If you are using an authorized agent to exercise your right to opt
            out we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
            <br />
            Will your information be shared with anyone else? <br />
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf, following the same strict privacy
            protection obligations mandated by the CCPA. <br />
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information. <br />
            Apical Fitness Corp. has not disclosed, sold, or shared any personal
            information to third parties for a business or commercial purpose in
            the preceding twelve (12) months. Apical Fitness Corp. will not sell
            or share personal information in the future belonging to website
            visitors, users, and other consumers. <br />
          </p>

          <h4 className="my-3 font-inter font-semibold">
            Your rights with respect to your personal data
          </h4>
          <p className="underline">
            Right to request deletion of the data-Request to delete
          </p>
          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her night to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities,
          </p>
          <p className="underline">
            Right to be informed- Request to know:
            <br />
          </p>
          <p>Depending on the circumstances, you may have right to know:</p>
          <ul>
            <li>whether we collect and use your personal information;</li>
            <li>The categories of personal information that we collect;</li>
            <li>
              The purposes for which the collected personal information is used;
            </li>
            <li>
              whether we sell or share personal information to third parties;
            </li>
            <li>
              the categories of personal information that we sold, shared, or
              disclosed for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose;
            </li>
            <li>
              the business or commercial purpose for collecting, selling, or
              sharing personal information and
            </li>
            <li>
              the specific pieces of personal information we collected about
              you.
            </li>
          </ul>
          <p>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identity individual data to verify a
            consumer request.
          </p>
          <p className="underline">
            Right to Non-Discrimination for the Exercise of a Consumer's Privacy
            Rights
          </p>
          <p>
            We will not discriminate against you if you exercise your privacy
            nights. Right to Limit Use and Disclosure of Sensitive Personal
            information We do not process consumer's sensitive personal
            information.
          </p>

          <p className="underline">Verification process</p>
          <p>
            Upon receiving your request we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g., phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate. <br />
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p className="underline">Other privacy rights</p>
          <ul className="list-inside list-disc">
            <li>
              You may object to the processing of your personal information;
            </li>
            <li>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information;
            </li>
            <li>
              You can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA;
            </li>
            <li>
              You may request to opt out from future selling or sharing of your
              personal information to third parties. Upon receiving an opt-out
              request, we will act upon the request as soon as feasibly
              possible, but no later than fifteen (15) days from the date of the
              request submission;
            </li>
          </ul>
          <br />
          <p>
            To exercise these rights, you can contact us by email a
            info@apicalfitness.com , or by referring to the contact details at
            the bottom of this document. If you have a complaint about how we
            handle your data, we would like to hear from you.
          </p>
          <h3 className="my-3 font-inter font-semibold">
            11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </h3>
          <p>
            In Short: Yes, if you are a resident of Virginia, you may be granted
            specific rights regarding access to and use of your personal
            information
          </p>
          <h4 className="my-3 font-inter font-semibold">
            Virginia CDPA Privacy Notice
          </h4>
          <p>
            Under the Virginia Consumer Data Protection Act (CDPA): <br />
            “Consumer" means a natural person who is a resident of the
            Commonwealth acting only in an individual or household context. It
            does not include a natural person acting in a commercial or
            employment context. <br />
            "Personal data" means any information that is linked or reasonably
            linkable to an identified or identifiable natural person "Personal
            data" does not include de-identified data or publicly available
            information. <br />
            "Sale of personal data" means the exchange of personal data for
            monetary consideration <br />
            If this definition "consumer" applies to you, we must adhere to
            certain rights and obligations regarding your personal data <br />
            The information we collect, use, and disclose about you will vary
            depending on how you interact with Apical Fitness Corp, and our
            Services. To find out more, please visit the following
          </p>
          <ul>
            <li>Personal data we collect;</li>
            <li>How we use your personal data;</li>
            <li>When and with whom we share your personal data;</li>
            <li>Your nights with respect to your personal data;</li>
            <li>
              Right to be informed whether or not we are processing your
              personal data;
            </li>
            <li>Right to correct inaccuracies in your personal data;</li>
            <li>Right to request deletion of your personal data;</li>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us;
            </li>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              promising in furtherance of decisions that produce legal or
              similarly significant effects ("profiling").
            </li>
          </ul>
          <p>
            Apical Fitness Corp has not sold any personal data to third parties
            for business or commercial purposes. Apical Fitness Corp. will not
            sell personal data in the future belonging to website visitors,
            users, and other consumers.
          </p>
          <p className="underline">
            Exercise.your.rights provided under the Virginia COPA
          </p>
          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice. <br />
            You may contact us by email at info@apicalfitness.com by visiting
            https://www.ApicalFitness.com , or by referring to the contact
            details at the bottom of this document.
          </p>

          <h3 className="my-3 font-inter font-semibold">
            12. DO WE MAKE UPDATES TO THIS NOTICE?
          </h3>
          <p>
            If you have questions or comments about this notice, you may email
            us at info@apicalfitness.com or by post to: <br />
            Apical Fitness Corp. <br />
            18 King Street East <br />
            Suite 1400 <br />
            Toronto, Ontario M5C 1C4 <br />
            Canada
          </p>
          <h3 className="my-3 font-inter font-semibold">
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h3>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it To request to review, update,
            or delete your personal information, please visit
            https://www.Apicalfitness.com
          </p>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicyPartner;
