const Message = ({ message, msgType, text, additionalClasses }) => {
  return (
    <>
      {msgType === "success" ? (
        <p
          className={
            message
              ? `successmsg text-left my-4 ${
                  text === "center"
                    ? "text-center"
                    : text === "left"
                    ? "text-left"
                    : ""
                } ${additionalClasses ? additionalClasses : ""}`
              : "offscreen"
          }
          aria-live="assertive"
        >
          {message}
        </p>
      ) : (
        <p
          className={
            message
              ? `errmsg text-left my-4 ${
                  text === "center"
                    ? "text-center"
                    : text === "left"
                    ? "text-left"
                    : ""
                } ${additionalClasses ? additionalClasses : ""}`
              : "offscreen"
          }
          aria-live="assertive"
        >
          {message}
        </p>
      )}
    </>
  );
};

export default Message;
