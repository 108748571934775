import bgImg from "../assets/images/bg_faqs.jpg";
import Ask from "../components/Ask";
import Footer from "../components/Footer";
import { ContactProvider } from "../contexts/ContactContext";

function FaqsPage() {
  return (
    <section className="">
      {/* Header */}
      <section
        className="relative"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        {/* <img src={bgImg} alt="barbells" className="absolute top-0 right-0" /> */}
        <div className="relative px-8 py-12 container">
          <h1 className="text-5xl md:text-8xl md:px-24 font-bold">
            Frequently Asked <br />{" "}
            <span className="text-yellow">Questions</span>
          </h1>
        </div>
      </section>
      <section className="container mx-auto px-8 py-12">
        <div className="text-yellow flex flex-col gap-10 lg:px-8">
          {faqsArr.map((faq, index) => {
            return (
              <div className="flex flex-col gap-2">
                <p className="text-2xl md:text-3xl lg:text-4xl font-bold">
                  {faq.question}
                </p>
                <p className="">{faq.answer}</p>
              </div>
            );
          })}
        </div>
      </section>
      <ContactProvider>
        <Ask />
      </ContactProvider>
      <Footer />
    </section>
  );
}

export default FaqsPage;

const faqsArr = [
  {
    question: "How can I become a member?",
    answer:
      "You can easily become a member by selecting and purchasing one of the membership packages featured on our website.",
  },
  {
    question: "How long is the plan period?",
    answer: "The subscription period is every two weeks.",
  },
  {
    question: "How do I download the mobile app?",
    answer:
      "You can download our mobile app from either the Apple Store or the Play Store.",
  },
  {
    question: "How are the plans different?",
    answer:
      "We offer a range of plans, including 'Bronze,' 'Silver,' and 'Gold,' each with varying services and prices available at our sports centers.",
  },
  {
    question:
      "Can I upgrade my membership plan, for example, from bronze to gold?",
    answer:
      "Yes, you have the flexibility to upgrade your plan at any time. The cost of your upgraded plan will be charged, and any remaining balance from your previous plan will be reimbursed within 3-5 business days.",
  },
  {
    question:
      "Can I downgrade my membership plan, for example, from gold to bronze?",
    answer:
      "Yes, you can downgrade your plan. However, you must first cancel your existing subscription, and your new plan will become active on the next renewal date.",
  },
  {
    question: "How do I access the fitness centers?",
    answer:
      "To access our fitness centers, you need to present the QR code available within the Apical Fitness mobile application. Once the QR code is scanned, you'll be granted entry to the center.",
  },
  {
    question: "How can I use a QR code?",
    answer:
      "The QR code is located on the QR page in the Apical Fitness mobile application and refreshes every 5 seconds.",
  },
  {
    question: "How can I find fitness centers in your network?",
    answer:
      "You can discover our partner fitness centers available under your package in the mobile app.",
  },
  {
    question: "Can multiple people use one account?",
    answer: "No, each plan is designated for a single user.",
  },
  {
    question: "Are there any usage restrictions?",
    answer: "Each user is limited to one entry per day.",
  },
  {
    question: "Can I use different sports centers in the same month?",
    answer:
      "Absolutely, you can visit a different fitness center each time you work out.",
  },
  {
    question:
      "If I have a 'Gold' plan, can I also use the fitness centers included in the Bronze and Silver plans?",
    answer:
      "Yes, 'Gold' members have access to all partner sports centers within our network. Additionally, users with a higher plan can use fitness centers belonging to lower-tier plans.",
  },
  {
    question: "Do I need an internet connection to scan a QR code?",
    answer:
      "Yes, you'll need an active internet connection to retrieve the QR code. If you don't have mobile internet, you can connect to the Wi-Fi network available at the fitness center.",
  },
  {
    question:
      "I encountered an error while entering my name during registration. How can I correct it?",
    answer:
      "To update your first and last name, please reach out to Apical Fitness customer services for assistance.",
  },
];
