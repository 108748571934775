import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ManagePlansConsumer } from "../../contexts/ManagPlansContext";
import plansData from "../../data/plansData";
import checkedIcon from "../../assets/icons/checked.svg";
import { calculateDiscount } from "../../utils/Helpers";

const Plan = ({ planName, discover, freez }) => {
  const {
    selectPlan,
    selectedPlan,
    setIsFreezModalOpen,
    setIsPlansModalOpen,
    planStatus,
  } = ManagePlansConsumer();

  const { name, bg, price, features } = plansData.find(
    (plan) => plan.name === planName
  );

  const location = useLocation();

  return (
    <div className="bg-dark-600 text-light rounded-md py-8 mt-5 mx-auto max-w-xs self-stretch shadow shadow-dark flex flex-col">
      <>
        <div className="text-center pb-6">
          <span className="text-6xl font-bold">
            <span className="text-3xl">$</span>
          </span>
          {(selectedPlan !== name && selectedPlan === "bronze") ||
          (name === "gold" && selectedPlan === "silver") ? (
            <>
              <del className="text-5xl font-bold mr-2">{price}</del>{" "}
              <span className="text-6xl font-bold">
                {calculateDiscount(price, 10)}
              </span>
            </>
          ) : (
            <>
              <span className="text-6xl font-bold">{price}</span>
            </>
          )}
          /<span className="text-neutral">Biweekly</span>
        </div>
        <div className="relative flex justify-center items-center">
          <img src={bg} alt={`${name} background`} className="w-full" />
          <h5 className="absolute text-dark-600 font-bold text-xl tracking-widest uppercase">
            {name}
          </h5>
        </div>
        <ul
          className="pt-2 pb-6 px-2 lg:px-4 text-neutral"
          style={{ minHeight: "190px" }}
        >
          {features.map((feature, index) => (
            <li key={index} className="flex mt-4 gap-3">
              <div className="mt-1 shrink-0">
                <img src={checkedIcon} alt="checked icon" />
              </div>
              <span className="text-left">{feature}</span>
            </li>
          ))}
        </ul>

        <div className="mt-auto">
          {(selectedPlan !== name && selectedPlan === "bronze") ||
          (name === "gold" && selectedPlan === "silver") ? (
            <p className="mb-6 bg-yellow text-dark text-center py-1 font-bold">
              Get -10% Discount by changing plan
            </p>
          ) : (
            <p className="mb-12 py-1 " />
          )}
          {location.pathname ===
          "/account-settings/subscribe-plan" ? null : !freez &&
            selectedPlan === name ? (
            <button
              className="p-3 bg-green text-white rounded block w-fit mx-auto"
              onClick={() => setIsPlansModalOpen(true)}
            >
              CHANGE PLAN
            </button>
          ) : selectedPlan === name && planStatus === "Frozen" ? (
            <button className="py-2 px-3 bg-dark-600 border-4 border-green rounded text-green text-xl block mx-auto cursor-not-allowed opacity-50">
              FROZEN PLAN
            </button>
          ) : selectedPlan === name && planStatus === "Active" ? (
            <button
              className="py-2 px-3 bg-dark-600 border-4 border-green rounded text-green text-xl block mx-auto"
              onClick={setIsFreezModalOpen}
            >
              FREEZE PLAN
            </button>
          ) : (
            <Link
              to="/account-settings/subscribe-plan"
              className="text-white bg-green py-3 px-6 mt-4 mb-6 rounded text-center block w-fit mx-auto"
              onClick={() => selectPlan(planName)}
            >
              SELECT PLAN
            </Link>
          )}
          {discover ? (
            <HashLink
              to={`/discover?selected_plan=${planName}`}
              className="text-green text-center block w-fit mx-auto mt-6 text-sm sm:text-base"
            >
              Discover related gyms
            </HashLink>
          ) : null}
        </div>
      </>
    </div>
  );
};

export default Plan;
