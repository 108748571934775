import { Routes, Route, useLocation } from "react-router-dom";
// Context Providers
import { AccountSettingsProvider } from "./contexts/AccountSettingsContext";
import { SignupProvider } from "./contexts/SignupFormContext";
import { PartnersProvider } from "./contexts/PartnersContext";
import { LoginProvider } from "./contexts/LoginContext";
import { ManagePlansProvider } from "./contexts/ManagPlansContext";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PartnerProvider } from "./contexts/BecomePartnerContext";
// Components
import Navbar from "./components/navbar/Navbar";
import PersonalData from "./components/account_settings/PersonalData";
import ChangePassword from "./components/account_settings/ChangePassword";
import ChangeEmail from "./components/account_settings/ChangeEmail";
import ChangeMembership from "./components/account_settings/ChangeMembership";
import ForgotPassword from "./components/account_settings/ForgotPassword";
import NewPassword from "./components/account_settings/NewPassword";
import RequireAuth from "./utils/RequireAuth";
import RequireUnauth from "./utils/RequireUnauth";
// Pages
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Help from "./pages/Help";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import PartnerDetail from "./pages/PartnerDetail";
import DownloadApp from "./pages/DownloadApp";
import AccountSettings from "./pages/AccountSettings";
import SubscribeNewPlan from "./pages/SubscribeNewPlan";
import LoginForgotPassword from "./pages/LoginForgotPassword";
import LoginResetPassword from "./pages/LoginResetPassword";
import PageNotFound from "./pages/PageNotFound";
import BecomePartner from "./pages/BecomePartner";
import PrivacyPolicyPartner from "./pages/PrivacyPolicyPartner";
import "@glidejs/glide/dist/css/glide.core.min.css";
import FaqsPage from "./pages/FaqsPage";
import DiscoverPage from "./pages/DiscoverPage";
import { useEffect } from "react";

const paypalScriptProviderOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  vault: true,
  currency: "CAD",
  intent: "subscription",
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <LoginProvider>
      <AccountSettingsProvider>
        <Navbar />
      </AccountSettingsProvider>
      <Routes>
        <Route
          path="/"
          element={
            <SignupProvider>
              <PartnersProvider>
                <Home />
              </PartnersProvider>
            </SignupProvider>
          }
        />
        <Route path="/faqs" element={<FaqsPage />} />
        <Route
          path="/discover"
          element={
            <SignupProvider>
              <PartnersProvider>
                <DiscoverPage />
              </PartnersProvider>
            </SignupProvider>
          }
        />
        <Route
          path="/partners/:partnerId"
          element={
            <PartnersProvider>
              <PartnerDetail />
            </PartnersProvider>
          }
        />
        <Route
          path="signup"
          element={
            <SignupProvider>
              <RequireUnauth>
                <Signup />
              </RequireUnauth>
            </SignupProvider>
          }
        />
        <Route
          path="login"
          element={
            <RequireUnauth>
              <Login />
            </RequireUnauth>
          }
        />
        <Route path="login/forgot-password" element={<LoginForgotPassword />} />
        <Route path="login/reset-password" element={<LoginResetPassword />} />
        <Route path="help" element={<Help />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="partner-privacy-policy"
          element={<PrivacyPolicyPartner />}
        />

        <Route
          path="become-partner"
          element={
            <PartnerProvider>
              <BecomePartner />
            </PartnerProvider>
          }
        />

        <Route
          path="terms-of-use"
          element={
            <SignupProvider>
              <PayPalScriptProvider options={paypalScriptProviderOptions}>
                <TermsOfUse />
              </PayPalScriptProvider>
            </SignupProvider>
          }
        />
        <Route
          path="download-app"
          element={
            <RequireAuth>
              <DownloadApp />
            </RequireAuth>
          }
        />
        <Route
          path="account-settings"
          element={
            <AccountSettingsProvider>
              <RequireAuth>
                <AccountSettings />
              </RequireAuth>
            </AccountSettingsProvider>
          }
        >
          <Route index element={<PersonalData />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route
            path="change-password/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="change-password/new-password"
            element={<NewPassword />}
          />
          <Route path="change-email" element={<ChangeEmail />} />
          <Route
            path="change-membership"
            element={
              <ManagePlansProvider>
                <ChangeMembership />
              </ManagePlansProvider>
            }
          />
          <Route
            path="subscribe-plan"
            element={
              <SignupProvider>
                <ManagePlansProvider>
                  <PayPalScriptProvider options={paypalScriptProviderOptions}>
                    <SubscribeNewPlan />
                  </PayPalScriptProvider>
                </ManagePlansProvider>
              </SignupProvider>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ScrollToTop />
    </LoginProvider>
  );
}

export default App;
