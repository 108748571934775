import facility1Img from "../../assets/images/facilities/facility_1.png";
import facility2Img from "../../assets/images/facilities/facility_2.png";
import facility3Img from "../../assets/images/facilities/facility_3.png";
import facility4Img from "../../assets/images/facilities/facility_4.png";
import defaultImg from "../../assets/images/rundofase_2.jpg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Glide from "@glidejs/glide";
import { PartnerConsumer } from "../../contexts/BecomePartnerContext";
import FaciltiesCarousel from "./FacilitiesCarousel";

const sliderConfiguration = {
  gap: 20,
  perView: 1,
  startAt: 0,
  type: "slider",
  focusAt: "center",
  peek: { before: 0, after: 100 },
};

function Facilities({ partners }) {
  let slider;

  const facilitiesArr = partners;

  useEffect(() => {
    // slider = new Glide("#facilities_slider.glide", sliderConfiguration);
    // slider.mount();
  }, []);

  return (
    <section id="discover">
      <p className="text-4xl text-center font-bold my-4">
        Facilities that recently joined our network
      </p>
      {/* For Desktop View */}
      <div className="w-full gap-8 my-8 hidden lg:flex">
        {facilitiesArr?.length
          ? facilitiesArr.map((facility, index) => {
              return (
                <div className="w-1/4 basis-1/4" key={index}>
                  <Link to={`/partners/${facility.id}`}>
                    <div className="w-full h-40 rounded-lg overflow-hidden">
                      <img
                        src={facility.images[0]?.image || defaultImg}
                        alt="facility"
                        className="h-full w-full object-cover"
                      />
                    </div>
                    <p className="font-bold my-2 text-2xl">{facility.name}</p>
                    <p className="text-xl">{facility.categories[0]?.title}</p>
                    <p className="text-sm">{facility.address_line1}</p>
                  </Link>
                </div>
              );
            })
          : null}
      </div>
      {/* For Mobile View */}
      <div className="block lg:hidden">
        <FaciltiesCarousel facilities={facilitiesArr} />
      </div>
      {/* Old Way for Mobile */}
      {/* <div id="facilities_slider" className="glide my-8 lg:hidden">
        <div className="glide__track" data-glide-el="track">
          <ul id="testimonials_carousel" className="glide__slides">
            {facilitiesArr.map((facility, index) => {
              return (
                <li
                  className="glide__slide slider min-h-[300px] min-w-[300px]"
                  key={index}
                >
                  <Link to={`/partners/${facility.id}`}>
                    {" "}
                    <img
                      src={facility.images[0]?.image}
                      alt="facility"
                      className="]"
                    />
                    <p className="font-bold my-2 text-2xl">{facility.name}</p>
                    <p className="text-xl">{facility.categories[0]?.title}</p>
                    <p className="text-sm">{facility.address_line1}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div> */}
      <Link
        to="/discover"
        className="block w-fit mx-auto border-2 border-dark px-4 py-2 rounded my-4 mt-12"
      >
        SEE MORE
      </Link>
    </section>
  );
}

export default Facilities;

const facilitiesArr = [
  {
    image: facility1Img,
    name: "Rundofase",
    category: "Fitness",
    location: "2715 Ash Dr. San Jose, South Dakota 83475",
  },
  {
    image: facility2Img,
    name: "Dalttechnology",
    category: "Fitness",
    location: "6391 Elgin St. Celina, Delaware 10299",
  },
  {
    image: facility3Img,
    name: "J-Texon",
    category: "Fitness",
    location: "3517 W. Gray St. Utica, Pennsylvania 57867",
  },
  {
    image: facility4Img,
    name: "Funholding",
    category: "Fitness",
    location: "4517 Washington Ave. Manchester, Kentucky 39495",
  },
];
