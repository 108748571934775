import { useMemo } from "react";
import rundofaseLogo from "../../../assets/images/rundofase_logo.jpg";

const PartnerDetailHeader = ({ name, categories, subscribedPlans }) => {
  const planTier = useMemo(() => {
    if (subscribedPlans.length > 0) {
      return subscribedPlans.includes("gold")
        ? "gold"
        : subscribedPlans.includes("silver")
        ? "silver"
        : "bronze";
    }

    return "bronze";
  }, [subscribedPlans]);

  return (
    <header>
      <div className="flex items-end gap-3 flex-wrap">
        {/* Gym Name */}
        <h2 className="text-yellow text-3xl sm:text-6xl font-semibold">
          {name}
        </h2>
        <div className="flex gap-3">
          {/* User Plan */}
          <PlanChip name={planTier} />
          {/* Gym Categories */}
          <div className="flex justify-center md:justify-start flex-wrap gap-2">
            {categories.map((category) => {
              return (
                <span
                  key={category.id}
                  className="bg-dark-600 py-1 px-2 rounded-full text-xs border border-white bg-transparent"
                >
                  {category.title}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </header>
  );
};

function PlanChip({ name }) {
  function getBgColor() {
    switch (name) {
      case "bronze":
        return "bg-[#CD7F32]";
      case "silver":
        return "bg-[#C0C0C0]";
      case "gold":
        return "bg-[#FFD700]";
      default:
        return "bg-[#B2660B]";
    }
  }

  return (
    <span
      className={`${getBgColor()} py-1 px-2 rounded-full text-sm bg-transparent capitalize`}
    >
      {name}
    </span>
  );
}

export default PartnerDetailHeader;
