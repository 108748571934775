import ReactDOM from "react-dom";
import { ManagePlansConsumer } from "../../contexts/ManagPlansContext";

const FreezPlan = ({ isOpen, onClose }) => {
  const { freezePlan } = ManagePlansConsumer();
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 h-xl w-full md:max-w-xl z-50 p-12 bg-white text-dark text-center">
      <div className="relative">
        <button
          className="absolute -top-6 -right-4 text-xl text-dark-600 font-bold"
          onClick={onClose}
        >
          X
        </button>
        <h3 className="text-3xl font-bold">FREEZE PLAN</h3>
        <p className="font-bold">Are you sure you want to freeze a plan?</p>
        <p className="my-6 px-0 lg:px-12">
          The plan will be frozen{" "}
          <span className="font-bold">for six months</span>. You will have the
          opportunity to unfreeze it during this period. If you haven't unfrozen
          the plan and six months have passed, our program will start debiting
          the money for the plan again.
        </p>
        <button
          className="bg-green text-white py-2 px-3 rounded uppercase"
          onClick={(e) => {
            onClose(e);
            freezePlan();
          }}
        >
          FREEZE PLAN
        </button>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default FreezPlan;
