import { useMemo } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const Map = ({ lat, lng }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const center = useMemo(
    () => ({ lat: parseFloat(lat), lng: parseFloat(lng) }),
    [lat, lng]
  );

  if (!isLoaded)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <PuffLoader color="#25AB75" />
      </div>
    );

  return (
    <GoogleMap
      zoom={11}
      center={center}
      mapContainerClassName="w-full h-full rounded"
    >
      <MarkerF
        position={center}
        icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
      />
    </GoogleMap>
  );
};

export default Map;
