import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { LoginConsumer } from "../contexts/LoginContext";
// Images
import logo from "../assets/images/logo.svg";
import linesPic from "../assets/images/topographic_3.svg";
import appStoreImg from "../assets/images/App_Store.png";
import googlePlayImg from "../assets/images/Google_Play.png";
// Icons
import aboutUsIcon from "../assets/icons/about_us.svg";
import discoverIcon from "../assets/icons/discover.svg";
import documentsIcon from "../assets/icons/terms_of_use.svg";
import privacyIcon from "../assets/icons/privacy.svg";
import signupIcon from "../assets/icons/signup.svg";
import loginIcon from "../assets/icons/login.svg";
import facebookIcon from "../assets/icons/facebook.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import telegramIcon from "../assets/icons/telegram.svg";
import phoneIcon from "../assets/icons/phone.svg";
import emailIcon from "../assets/icons/email.svg";
import Container from "./Container";

const Footer = () => {
  const { isAuth } = LoginConsumer();

  return (
    <footer className="bg-dark text-white py-12 relative">
      <img
        src={linesPic}
        alt="Lines background"
        className="absolute -left-32 top-0 max-h-full lg:w-1/2 rotate-180 hidden lg:block"
      />
      <Container>
        <div className="flex justify-between gap-12 lg:gap-5 py-8 overflow-x-auto no-scrollbar">
          <div className="shrink-0">
            <HashLink to="/#">
              <img src={logo} alt="Lifetime Fitness Logo" className="w-40" />
            </HashLink>
          </div>
          <ul className="shrink-0 text-left">
            <h3 className="text-4xl mb-8">Our Company</h3>
            <li className="mb-6 text-xl">
              <HashLink to="/#about" className="flex gap-2">
                <img src={aboutUsIcon} alt="About us icon" /> About Us
              </HashLink>
            </li>
            <li className=" mb-6 text-xl">
              <Link to="/discover" className="flex gap-2">
                <img src={discoverIcon} alt="Discover icon" /> Discover
              </Link>
            </li>
            <li className="mb-6 text-xl">
              {" "}
              <Link to="/terms-of-use" className="flex gap-2">
                <img src={documentsIcon} alt="Documents us icon" /> Terms of Use
              </Link>
            </li>
            <li className="mb-6 text-xl">
              {" "}
              <Link to="/privacy-policy" className="flex gap-2">
                <img src={privacyIcon} alt="Privacy policy icon" /> Privacy
                Policy
              </Link>
            </li>
          </ul>
          {isAuth ? (
            <ul className="shrink-0 text-left">
              <h3 className="text-4xl mb-8">Download App</h3>
              <li className="mb-4">
                <a
                  target="_blank"
                  href="https://apps.apple.com/in/app/apical-fitness/id6444069453"
                  className="inline-block mx-auto md:ml-0"
                  rel="noreferrer"
                >
                  <img src={appStoreImg} alt="App store link" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.apicalfitness.apicalfitness"
                  className="inline-block mx-auto md:ml-0"
                  rel="noreferrer"
                >
                  <img src={googlePlayImg} alt="Google play link" />
                </a>
              </li>
            </ul>
          ) : (
            <ul className="shrink-0 text-left">
              <h3 className="text-4xl mb-8">Your Account</h3>
              <li className="mb-6 text-xl">
                <Link to="/signup" className="flex gap-2">
                  <img src={signupIcon} alt="Signup icon" /> Sign Up
                </Link>
              </li>
              <li className=" mb-6 text-xl">
                <Link to="/login" className="flex gap-2">
                  <img src={loginIcon} alt="Login icon" /> Log In
                </Link>
              </li>
            </ul>
          )}
          <ul className="shrink-0 text-left">
            <h3 className="text-4xl mb-8">Follow Us</h3>
            <li className="mb-6 text-xl">
              <a
                href="https://facebook.com/100086470054769/"
                className="flex justify-center sm:justify-start gap-2"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookIcon} alt="Facebook icon" /> Facebook
              </a>
            </li>
            <li className="mb-6 text-xl">
              {" "}
              <a
                href="https://telegram.org"
                className="flex justify-center sm:justify-start gap-2"
                target="_blank"
                rel="noreferrer"
              >
                <img src={telegramIcon} alt="Telegram icon" /> Telegram
              </a>
            </li>
            <li className="mb-6 text-xl">
              {" "}
              <a
                href="https://instagram.com/apical_fitness?igshid=YmMyMTA2M2Y"
                className="flex justify-center sm:justify-start gap-2"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramIcon} alt="Instagram icon" /> Instagram
              </a>
            </li>
          </ul>
          <ul className="shrink-0 basis-80 text-left">
            <h3 className="text-4xl mb-8">Contact Us</h3>
            <li className="flex gap-2 sm:w-full mb-6 text-xl items-start">
              <img src={phoneIcon} alt="Phone icon" />{" "}
              <div>
                <p className="font-semibold text-2xl mb-1 text-left">Call Us</p>
                <a href="tel:+16479473003"> +1 647 947 3003</a>
              </div>
            </li>
            <li className="flex gap-2 sm:w-full mb-6 text-xl items-start">
              <img src={emailIcon} alt="Email icon" />{" "}
              <div className="flex-1">
                <p className="font-semibold text-2xl mb-1 text-left">Email</p>
                <a href="mailto:info@apicalfitness.com" className="block">
                  info@apicalfitness.com
                </a>
              </div>
            </li>
          </ul>
        </div>
        <hr />
        <p className="text-center sm:text-right text-dark-400 text-sm pt-10">
          Copyright {new Date().getFullYear()} Apical Fitness.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
