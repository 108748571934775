import React from "react";
import PasswordChecklist from "react-password-checklist";
import { LoginConsumer } from "../../contexts/LoginContext";
// Icons
import uncheckedIcon from "../../assets/icons/uncheck_red.svg";
import checkedIcon from "../../assets/icons/checked.svg";
import Message from "../Message";

const ResetPasswordComponent = () => {
  const {
    newPassword,
    setNewPassword,
    passwordInputType,
    showHidePassword,
    passwordInputIcon,
    repeatNewPassword,
    setRepeatNewPassword,
    handleResetPassword,
    successMsg,
    errMsg,
  } = LoginConsumer();

  return (
    <div className="max-w-7xl container px-4 sm:px-6 lg:px-12 xl:px-0 relative flex flex-col items-center lg:block">
      <div className="mb-6">
        <h1 className="text-3xl text-center lg:text-left font-semibold uppercase mb-3">
          CREATE NEW PASSWORD
        </h1>
        <p className="mb-12 text-lg">Enter your new password</p>
      </div>
      <form className="mt-5" onSubmit={handleResetPassword}>
        <div className="my-6 relative w-fit">
          <input
            type={passwordInputType}
            name="password"
            placeholder="Your new Password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="max-w-sm px-2 py-3 bg-dark-800 text-light rounded w-80 placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
          />
          <label
            htmlFor="newPassword"
            className="bg-dark-600 text-light text-xs px-1 absolute -top-2 left-2 rounded"
          >
            Create new password
          </label>
          <span
            className="absolute right-0 top-4 w-8 h-8 text-dark-400 cursor-pointer"
            onClick={showHidePassword}
          >
            {passwordInputIcon}
          </span>
        </div>

        <PasswordChecklist
          rules={["capital", "lowercase", "number", "minLength"]}
          minLength={6}
          value={newPassword}
          messages={{
            capital: "Uppercase letters",
            lowercase: "Lowercase letters",
            number: "Numbers",
            minLength: "Password has more than 6 characters",
          }}
          iconComponents={{
            ValidIcon: <img src={checkedIcon} alt="Checked" />,
            InvalidIcon: <img src={uncheckedIcon} alt="Unchecked" />,
          }}
          className="text-left my-3 password-checklist"
        />

        <div className="mt-6 mb-12 relative w-fit">
          <input
            type="password"
            name="repeat_password"
            placeholder="Repeat new password"
            value={repeatNewPassword}
            onChange={(e) => setRepeatNewPassword(e.target.value)}
            className="max-w-sm px-2 py-3 bg-dark-800 text-light rounded w-80 placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
          />
        </div>

        <Message
          msgType="success"
          message={successMsg}
          text="left"
          additionalClasses="max-w-xs"
        />
        <Message
          msgType="error"
          message={errMsg}
          text="left"
          additionalClasses="max-w-xs"
        />

        <button
          type="submit"
          className="p-3 bg-green text-white rounded block w-fit mx-auto mt-6 lg:ml-0"
        >
          SAVE CHANGES
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordComponent;
