import { useEffect } from "react";
import { ManagePlansConsumer } from "../contexts/ManagPlansContext";
import { LoginConsumer } from "../contexts/LoginContext";
import { SignupConsumer } from "../contexts/SignupFormContext";
import { calculateDiscount } from "../utils/Helpers";
import plansData from "../data/plansData";
import Plan from "../components/account_settings/Plan";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const SubscribeNewPlan = () => {
  const { newSelectedPlan } = ManagePlansConsumer();
  const { subscribePlan } = SignupConsumer();
  const { user } = LoginConsumer();

  const createSubscription = (_data, actions) => {
    return actions.subscription.create({
      plan_id:
        newSelectedPlan === "gold"
          ? process.env.REACT_APP_PAYPAL_GOLD_ID
          : newSelectedPlan === "silver"
          ? process.env.REACT_APP_PAYPAL_SILVER_ID
          : process.env.REACT_APP_PAYPAL_BRONZE_ID,
    });
  };

  const onApprove = async (data, _actions) => {
    subscribePlan(data, user.email);
  };

  const onError = (error) => {
    toast.error("Something went wrong!", {
      position: toast.POSITION.BOTTOM_CENTER,
      theme: "dark",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return plansData.map((plan) => {
    const { id, name, price } = plan;
    return (
      name === newSelectedPlan && (
        <div
          className="flex-1 flex flex-col-reverse items-center text-center lg:text-left lg:flex-row gap-12"
          key={id}
        >
          <div className="border-4 lg:border-y-0 border-green max-w-xl md:min-h-screen bg-dark-600 p-6 lg:p-20 text-center flex-1 lg:flex-auto">
            <div>
              <h2 className="text-3xl mb-4 uppercase">
                {newSelectedPlan} MEMBERSHIP
              </h2>
              <p className="mb-8">
                Pay one subscription and visit different gyms. <br /> Use our
                app as a ticket to the gyms
              </p>
            </div>
            <div className="w-fit mx-auto">
              <Plan
                planName={newSelectedPlan}
                freez={false}
                notDiscover={true}
              />
            </div>
          </div>
          <div className="flex-1 lg:flex-auto">
            <h2 className="text-4xl font-semibold uppercase mb-6">
              Subscribe a plan
            </h2>
            <p className="leading-7 text-lg font-light mb-4">
              Hello {user.first_name}, <br />
              You are confirming the payment of subscription{" "}
              <br className="hidden lg:block" />
              {(newSelectedPlan !== name && newSelectedPlan === "bronze") ||
              (name === "gold" && newSelectedPlan === "silver")
                ? calculateDiscount(price, 10)
                : price}
              $ per two weeks.
              <span className="block mt-4">
                It may take a few moment for this transaction.
              </span>
            </p>

            <div className="lg:w-96">
              <PayPalButtons
                style={{
                  label: "pay",
                  tagline: false,
                }}
                createSubscription={createSubscription}
                onApprove={onApprove}
                onError={onError}
              />
            </div>
          </div>
          <ToastContainer />
        </div>
      )
    );
  });
};

export default SubscribeNewPlan;
