// Images
import boxerImg from "../assets/images/boxer.svg";
import homeGradientImg from "../assets/images/home_hero_gradient.svg";
import linesPic from "../assets/images/topographic_6.svg";
import LoginComponent from "../components/login/LoginComponent";

const Login = () => {
  return (
    <section className="bg-dark text-white py-12 min-h-screen relative flex items-center justify-center lg:justify-start">
      <img
        src={boxerImg}
        alt="Boxer Background"
        className="absolute right-0 top-0 h-full w-full lg:w-auto object-cover"
      />
      <img
        src={homeGradientImg}
        alt="Gradient"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-full hidden lg:block"
      />
      <img
        src={linesPic}
        alt="Lines background"
        className="absolute left-0 top-0 max-h-full hidden lg:block"
      />
      <LoginComponent />
    </section>
  );
};

export default Login;
