import { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";

const ContactContext = createContext(null);

const ContactProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showMsg, setShowMsg] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_CONTACT_US,
        JSON.stringify({
          name,
          email,
          message,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 200) {
        setSuccessMsg(
          "Your message is submitted successfully, Thank you for your interest!"
        );
        setShowMsg(true);
        setName("");
        setEmail("");
        setMessage("");
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response, verify your internet connection.");
        setShowMsg(true);
      } else {
        setErrMsg("Operation Failed, Try again!");
        setShowMsg(true);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMsg(false);
      setSuccessMsg("");
      setErrMsg("");
    }, 7000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showMsg]);

  return (
    <ContactContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        message,
        setMessage,
        handleSubmit,
        successMsg,
        errMsg,
        showMsg,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

const ContactConsumer = () => {
  return useContext(ContactContext);
};

export { ContactConsumer, ContactProvider };
