import Plan from "./Plan";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";

const ChoosePlan = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={React.Fragment}
    >
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center md:p-4 text-center">
            <Dialog.Panel className="w-full relative max-w-4xl rounded-lg bg-white px-4 py-8">
              <button
                className="absolute p-4 top-0 right-0 text-2xl text-dark-600 font-bold"
                onClick={onClose}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="1.45305"
                    y1="18.6318"
                    x2="19.1307"
                    y2="0.954091"
                    stroke="black"
                    stroke-width="2"
                  />
                  <line
                    x1="1.70711"
                    y1="1.29289"
                    x2="19.3848"
                    y2="18.9706"
                    stroke="black"
                    stroke-width="2"
                  />
                </svg>
              </button>
              <Dialog.Title
                as="h2"
                className="text-3xl font-bold uppercase mb-4 text-dark-600"
              >
                Select another plan
              </Dialog.Title>
              <Dialog.Description
                as="p"
                className="text-md font-semibold text-dark-600"
              >
                Pay one subscription and visit different gyms. <br /> Use our
                app as a ticket to the gyms.
              </Dialog.Description>
              <div className="flex justify-center items-center flex-wrap gap-3 text-white">
                <Plan planName="bronze" isSelectPlanBtn={true} />
                <Plan planName="silver" isSelectPlanBtn={true} />
                <Plan planName="gold" isSelectPlanBtn={true} />
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ChoosePlan;
