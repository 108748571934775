import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PartnersConsumer } from "../contexts/PartnersContext";
// Images
import rundofaseImg1 from "../assets/images/rundofase_1.jpg";
// Components
import PartnerDetailBody from "../components/partner/detail/PartnerDetailBody";
import PartnerDetailCarousel from "../components/partner/detail/PartnerDetailCarousel";
import PartnerDetailFooter from "../components/partner/detail/footer/PartnerDetailFooter";
import PartnerDetailHeader from "../components/partner/detail/PartnerDetailHeader";
import PuffLoader from "react-spinners/PuffLoader";
import Container from "../components/Container";
import Footer from "../components/Footer";

const converTo12HourFormat = (fullHourFormat) => {
  const [hh, minutes] = fullHourFormat.split(":");
  let hour = hh;
  let zone = "AM";

  if (hh > 12) {
    hour = hh - 12;
  }
  if (hh >= 12) {
    zone = "PM";
  }

  return `${hour}:${minutes} ${zone}`;
};

const PartnerDetail = () => {
  const { filteredPartners, loading } = PartnersConsumer();
  const { partnerId } = useParams();
  const contactRef = useRef(null);
  const [partner, setPartner] = useState();

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_ENV === "STAGING"
          ? `https://stage.srv.apicalfitness.com/api/partners/${partnerId}/contact-information/`
          : `https://srv.apicalfitness.com/api/partners/${partnerId}/contact-information/`,
        {
          headers: {
            "App-Type": "Web",
          },
        }
      )
      .then((response) => {
        contactRef.current = response.data;
      });
  }, [partnerId]);

  useEffect(() => {
    const partner = filteredPartners.filter(
      (item) => item.id === parseInt(partnerId)
    );
    setPartner(partner[0]);
    console.log(partner[0]);
  }, [partnerId, filteredPartners]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading && !partner) {
    return (
      <div className="h-screen flex items-center justify-center">
        <PuffLoader color="#25AB75" />
      </div>
    );
  }

  return (
    partner && (
      <main className="bg-dark text-white relative min-h-screen">
        <Container className="py-16">
          <PartnerDetailHeader
            name={partner.name}
            categories={partner.categories}
            logo={partner.logo}
            subscribedPlans={partner.subscribed_plans}
          />
          <PartnerDetailCarousel images={partner.images} name={partner.name} />
          <div className="w-full grid grid-cols-1 md:grid-cols-2 justify-items-start md:justify-items-end py-4 pt-8 gap-8">
            <PartnerDetailBody description={partner.description} />
            <div className="">
              <h3 className="text-xl font-inter font-bold mb-2">
                Gym operation hours
              </h3>
              {partner.opening_hours?.length ? (
                partner.opening_hours.map((item) => {
                  return (
                    <p key={item.id} className="my-2 text-md">
                      <span className="font-semibold">{item.weekday}:</span>{" "}
                      {converTo12HourFormat(item.from_hour)} -{" "}
                      {converTo12HourFormat(item.to_hour)}
                    </p>
                  );
                })
              ) : (
                <p>No information available!</p>
              )}
            </div>
          </div>
          <PartnerDetailFooter
            address={partner.address_line1}
            openingHours={partner.opening_hours}
            longitude={partner.long}
            latitude={partner.lat}
            contact={contactRef.current}
          />
        </Container>
        <Footer />
      </main>
    )
  );
};

export default PartnerDetail;
