import { Link } from "react-router-dom";
import { LoginConsumer } from "../../contexts/LoginContext";
import Message from "../Message";

const LoginComponent = () => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    passwordInputType,
    passwordInputIcon,
    showHidePassword,
    handleSubmit,
    errMsg,
  } = LoginConsumer();

  return (
    <div className="max-w-7xl container px-4 sm:px-6 lg:px-12 xl:px-0 relative flex flex-col items-center lg:block">
      <h1 className="text-center text-4xl w-80 mb-8 font-semibold">LOG IN</h1>
      <form className="w-80 text-center" onSubmit={handleSubmit}>
        <div className="my-3">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="max-w-sm px-2 py-3 bg-dark-800 text-light rounded w-80 placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
          />
        </div>
        <div className="mt-3 mb-8 relative">
          <input
            type={passwordInputType}
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="max-w-sm px-2 py-3 bg-dark-800 text-light rounded w-80 placeholder:text-dark-400 focus:outline focus:outline-green focus:placeholder:text-green"
          />
          <span
            className="absolute right-0 top-4 w-8 h-8 text-dark-400 cursor-pointer"
            onClick={showHidePassword}
          >
            {passwordInputIcon}
          </span>
          <Link
            to="forgot-password"
            className="text-right text-green ml-auto block w-fit mt-2"
          >
            Forgot password?
          </Link>
        </div>

        <Message msgType="error" message={errMsg} text="left" />

        <button className="text-white bg-green py-3 px-4 rounded w-52">
          LOG IN
        </button>
      </form>

      <p className="uppercase text-dark-400 mt-8">
        Dont Have an account?{" "}
        <Link to="/signup" className="text-green">
          Sign Up here
        </Link>
      </p>
    </div>
  );
};

export default LoginComponent;
