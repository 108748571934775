import { useEffect } from "react";
import PasswordChecklist from "react-password-checklist";
import { AccountSettingsConsumer } from "../../contexts/AccountSettingsContext";
// Icons
import uncheckedIcon from "../../assets/icons/uncheck_red.svg";
import checkedIcon from "../../assets/icons/checked.svg";
// Components
import SettingsContainer from "./SettingsContainer";

const NewPassword = () => {
  const {
    passwordInputType,
    newPassword,
    showHidePassword,
    passwordInputIcon,
    setNewPassword,
    repeatNewPassword,
    setRepeatNewPassword,
    handleResetPassword,
  } = AccountSettingsConsumer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SettingsContainer>
      <div>
        <h3 className="text-3xl text-center lg:text-left font-semibold uppercase mb-3">
          CREATE NEW PASSWORD
        </h3>
        <p className="mb-12 text-lg">Enter your new password</p>
        <form className="mt-5" onSubmit={handleResetPassword}>
          <div className="my-6 relative">
            <input
              type={passwordInputType}
              name="password"
              placeholder="Your new Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
            <span className="bg-dark-600 text-xs px-1 absolute -top-2 left-2 rounded">
              Create new password
            </span>
            <span
              className="absolute right-0 top-4 w-8 h-8 text-dark-400 cursor-pointer"
              onClick={showHidePassword}
            >
              {passwordInputIcon}
            </span>
          </div>

          <PasswordChecklist
            rules={["capital", "lowercase", "number"]}
            minLength={5}
            value={newPassword}
            messages={{
              capital: "Uppercase letters",
              lowercase: "Lowercase letters",
              number: "Numbers",
            }}
            iconComponents={{
              ValidIcon: <img src={checkedIcon} alt="Checked" />,
              InvalidIcon: <img src={uncheckedIcon} alt="Unchecked" />,
            }}
            validColor="#25ab75"
            invalidColor="red"
            className="text-left my-3 password-checklist"
          />

          <div className="mt-6 mb-12 relative w-full">
            <input
              type="password"
              name="repeat_password"
              placeholder="Repeat new password"
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
          </div>

          <button
            type="submit"
            className="p-3 bg-green text-white rounded block w-fit mx-auto lg:ml-0"
          >
            SAVE CHANGES
          </button>
        </form>
      </div>
    </SettingsContainer>
  );
};

export default NewPassword;
