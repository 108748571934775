import { Link } from "react-router-dom";

const PartnerCard = ({ mode, name, address, categories, image, id }) => {
  return (
    <Link
      to={`/partners/${id}`}
      className="shadow p-2 rounded-sm max-w-[350] lg:max-w-[400px]"
      style={{ minHeight: "340px" }}
    >
      <img
        src={image}
        alt={name}
        className="h-52 max-w-44 mb-2 object-cover rounded-sm"
      />
      <h3 className="text-2xl font-bold mb-2 mt-auto">{name}</h3>
      <div>
        {categories.map((category, index) => (
          <span key={category.title} className="text-base font-semibold mb-1">
            {index === 0 && category.title}
          </span>
        ))}
        <p className={mode === "dark" ? "text-dark-400 text-sm" : "text-sm"}>
          {address ? address : "No Address avilable!"}
        </p>
      </div>
    </Link>
  );
};

export default PartnerCard;
