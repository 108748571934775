import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import PuffLoader from "react-spinners/PuffLoader";
import pinNormal from "../../assets/icons/map/pin-normal.png";
import pinBronze from "../../assets/icons/map/pin-bronze.png";
import pinSilver from "../../assets/icons/map/pin-silver.png";
import pinGold from "../../assets/icons/map/pin-gold.png";
import { Link } from "react-router-dom";

function ViewMap({ partners }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [arePartnersLoaded, setArePartnersLoaded] = useState(false);
  const [filteredPartners, setFilteredPartners] = useState(
    partners || undefined
  );
  const [selectedPlaceIndex, setSelectedPlaceIndex] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("all");
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filters, setFilters] = useState({
    plan: "all",
    category: "all",
    location: "all",
  });

  function filterPartners({ planName, categoryName, locationName }) {
    let newFilteredPartners = partners;
    if (planName !== "all") {
      newFilteredPartners = newFilteredPartners.filter(
        (partner) => partner.subscribed_plans[0] === planName
      );
    }

    if (categoryName !== "all") {
      newFilteredPartners = newFilteredPartners.filter((partner) => {
        const catIndex = partner.categories.findIndex((category) => {
          return category.title === categoryName;
        });
        return catIndex !== -1;
      });
    }

    if (locationName !== "all") {
      newFilteredPartners = newFilteredPartners.filter((partner) => {
        return partner.city === locationName;
      });
    }

    setTimeout(() => {
      setFilteredPartners(newFilteredPartners);
    }, 0);
  }

  function extractCategories(partners) {
    const newCategories = new Set([]);
    partners.forEach((partner) => {
      partner.categories.forEach((category) => {
        newCategories.add(category.title);
      });
    });
    return Array.from(newCategories);
  }

  function extractLocations(partners) {
    const newLocations = new Set([]);
    partners.forEach((partner) => {
      newLocations.add(partner.city);
    });
    return Array.from(newLocations);
  }

  function getPinMarker(name) {
    if (name.toLowerCase() === "bronze") {
      return pinBronze;
    }
    if (name === "silver") {
      return pinSilver;
    }
    if (name === "gold") {
      return pinGold;
    }

    return pinNormal;
  }

  useEffect(() => {
    // setFilteredPartners([]);
    filterPartners({
      planName: filters.plan,
      categoryName: filters.category,
      locationName: filters.location,
    });

    setSelectedPlan(filters.plan);
  }, [filters]);

  useEffect(() => {
    // Setup Categories and Their Location from partners

    setCategories(extractCategories(partners));
    setLocations(extractLocations(partners));
  }, [partners]);

  return (
    <>
      <div className="flex items-center justify-center gap-10 uppercase my-4">
        <button
          className={`${
            selectedPlan === "all" ? "underline text-black" : "text-gray-700"
          }  cursor-point hover:underline}`}
          onClick={() => setFilters({ ...filters, plan: "all" })}
        >
          All
        </button>
        <button
          className={`${
            selectedPlan === "bronze" ? "underline text-black" : "text-gray-700"
          } cursor-point hover:underline}`}
          onClick={() => setFilters({ ...filters, plan: "bronze" })}
        >
          Bronze
        </button>
        <button
          className={`${
            selectedPlan === "silver" ? "underline text-black" : "text-gray-700"
          } cursor-point hover:underline}`}
          onClick={() => setFilters({ ...filters, plan: "silver" })}
        >
          Silver
        </button>
        <button
          className={`${
            selectedPlan === "gold" ? "underline text-black" : "text-gray-700"
          } cursor-point hover:underline}`}
          onClick={() => setFilters({ ...filters, plan: "gold" })}
        >
          Gold
        </button>
      </div>
      <div className="flex flex-col lg:flex-row justify-center gap-3 lg:gap-5 lg:gap-14">
        <select
          className="block mb-4 rounded p-2"
          placeholder="Sport Category"
          onChange={(e) => setFilters({ ...filters, category: e.target.value })}
        >
          <option value="all">All</option>
          {categories?.map((category) => {
            return (
              <option key={category} value={category}>
                {category}
              </option>
            );
          })}
        </select>
        <select
          className="block mb-4 rounded p-2"
          placeholder="Location"
          onChange={(e) => setFilters({ ...filters, location: e.target.value })}
        >
          <option value="all">All</option>
          {locations?.map((location) => {
            return (
              <option key={location} value={location}>
                {location}
              </option>
            );
          })}
        </select>
      </div>
      <div className="w-full lg:px-24 py-8">
        {isLoaded ? (
          <GoogleMap
            mapContainerClassName="w-full h-[400px] rounded"
            zoom={12}
            center={{
              lat: 43.6429251,
              lng: -79.387096,
            }}
          >
            {filteredPartners.map((partner, index) => {
              return (
                <MarkerF
                  position={{
                    lat: parseFloat(partner.lat),
                    lng: parseFloat(partner.long),
                  }}
                  key={partner.id}
                  onClick={() => {
                    setSelectedPlaceIndex(index);
                  }}
                  icon={getPinMarker(partner.subscribed_plans[0])}
                  // icon={pin}
                />
              );
            })}
            {selectedPlaceIndex !== null && (
              <InfoWindowF
                position={{
                  lat: parseFloat(filteredPartners[selectedPlaceIndex].lat),
                  lng: parseFloat(filteredPartners[selectedPlaceIndex].long),
                }}
                zIndex={1}
                options={{ pixelOffset: { width: 0, height: -50 } }}
                onCloseClick={() => setSelectedPlaceIndex(null)}
              >
                <div className="flex flex-col items-start gap-8">
                  <div className="flex flex-col items-start gap-2">
                    <img
                      src={
                        filteredPartners[selectedPlaceIndex]?.images[0]?.image
                      }
                      className="w-full max-w-[250px] max-h-[150px]"
                      alt="Partnerimage"
                    />
                    <p className="text-lg font-bold">
                      {filteredPartners[selectedPlaceIndex]?.name}
                    </p>
                    <p className="text-lg">
                      {
                        filteredPartners[selectedPlaceIndex]?.categories[0]
                          ?.title
                      }
                    </p>
                    <p className="text-md">
                      {filteredPartners[selectedPlaceIndex]?.address_line1}
                    </p>
                  </div>
                  <Link
                    to={`/partners/${filteredPartners[selectedPlaceIndex].id}`}
                    className="cursor-pointer underline font-bold"
                  >
                    Read More
                  </Link>
                </div>
              </InfoWindowF>
            )}
          </GoogleMap>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <PuffLoader color="#25AB75" />
          </div>
        )}
      </div>
    </>
  );
}

export default ViewMap;
