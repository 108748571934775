import Map from "./Map";

const PartnerDetailFooter = ({
  address,
  openingHours,
  longitude,
  latitude,
  contact,
}) => {
  return (
    <div className="mt-12 flex flex-col md:flex-col gap-6">
      {/* Contacts */}
      <div className="flex-1">
        <h3 className="text-xl font-inter font-bold mb-2">Contacts</h3>
        {contact ? (
          <>
            <a
              href="mailto:bill.sanders@example.com"
              className="block my-2 text-md"
            >
              {contact.email}
            </a>
            {contact.phone_numbers.map((number) => (
              <a
                href={`phoneto:${number}`}
                className="block my-2 text-md"
                key={number}
              >
                {number}
              </a>
            ))}
          </>
        ) : (
          <p>No Contacts Available!</p>
        )}
      </div>
      {/* Address */}
      <div className="flex-1">
        <h3 className="text-xl font-inter font-bold">Address</h3>
        <address className="not-italic text-lg my-2">
          {address ? address : "No Address Available!"}
        </address>
        {/* Map */}
        <div className="w-full h-[300px]">
          <Map lat={latitude} lng={longitude} />
        </div>
      </div>
      {/* Operation hours */}
      {/* <div className="flex-1">
        <h3 className="text-xl font-inter font-bold mb-2">
          Gym operation hours
        </h3>
        {openingHours.length ? (
          openingHours.map((item) => {
            return (
              <p key={item.id} className="my-2 text-md">
                <span className="font-semibold">{item.weekday}:</span>{" "}
                {item.from_hour.substring(0, 5)} -{" "}
                {item.to_hour.substring(0, 5)}
              </p>
            );
          })
        ) : (
          <p>No information available!</p>
        )}
      </div> */}
    </div>
  );
};

export default PartnerDetailFooter;
