import { HashLink } from "react-router-hash-link";
//Images
import homeHeroImg from "../assets/images/home_hero_bg.jpg";
import homeGradientImg from "../assets/images/home_hero_gradient.svg";
import linesPic from "../assets/images/topographic_6.svg";
import Container from "./Container";

const Hero = () => {
  const scrollWithOffset = async (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -88;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <section className="flex items-center min-h-screen relative py-6">
      <img
        src={homeHeroImg}
        alt="Fitness woman"
        className="absolute right-0 top-0 max-h-full h-full object-cover block md:w-2/3"
      />
      {/* Vertical Gradient From Bottom to Top */}
      <div
        className="absolute left-0 top-0 w-full h-full gradient-b-t md:hidden"
        style={{ zIndex: 1 }}
      ></div>
      <img
        src={homeGradientImg}
        alt="Gradient"
        className="hidden md:block absolute top-1/2 left-[35%] transform -translate-x-1/2 -translate-y-1/2 h-full max-h-full"
      />
      <img
        src={linesPic}
        alt="Lines background"
        className="hidden md:block absolute left-0 top-0 max-h-full"
      />
      <Container className="z-10">
        <h1 className="uppercase font-bold text-center lg:text-left text-5xl md:text-7xl mb-6">
          Subscribe Once <br />
          <span className="text-yellow">
            workout <br /> everywhere
          </span>
        </h1>
        <p className="mt-6 mb-8 text-sm text-center lg:text-left md:text-lg font-normal leading-normal">
          Pay one subscription and visit different fitness centres. Any
          location.
          <br className="hidden md:block" />
          Thousands of sports. Zero contracts. Zero activation fee.
        </p>
        <div className="flex flex-col lg:flex-row lg:gap-4 items-center">
          <p className="mt-6 mb-2 text-sm text-center lg:text-left md:text-lg font-normal leading-normal text-light-600">
            Starting From $24.99
          </p>
          <HashLink
            to="/#select-plan"
            className="bg-green text-white p-4 rounded font-normal mx-auto lg:mx-0 w-fit block text-center"
            scroll={(el) => scrollWithOffset(el)}
          >
            BECOME A MEMBER
          </HashLink>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
