import { useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { LoginConsumer } from "../../contexts/LoginContext";
import NavUser from "./NavUser";
import logo from "../../assets/images/logo.svg";
import Container from "../Container";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavuserOpen, setIsNavuserOpen] = useState(false);
  const ref = useRef();

  const { user, isAuth, imgSrc } = LoginConsumer();

  const scrollWithOffset = async (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -88;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    setIsOpen(false);
  };

  const onCloseNavuser = () => {
    setIsOpen(false);
    setIsNavuserOpen(false);
  };

  return (
    <nav className="bg-dark sticky top-0 z-40 navbar">
      <Container className="py-1">
        <div className="flex items-center justify-between h-16 w-100">
          <div className="flex items-center justify-between w-full">
            <HashLink
              to="/#"
              className="flex-shrink-0"
              scroll={(el) => scrollWithOffset(el)}
            >
              <img src={logo} alt="Lifetime Fitness logo" className="w-20" />
            </HashLink>
            <div className="hidden ml-auto xl:block">
              <div className="flex items-baseline space-x-5">
                <HashLink
                  to="/#about"
                  className="px-3 py-2 text-sm font-medium rounded-md text-light"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  ABOUT US
                </HashLink>

                <HashLink
                  to="/#explore"
                  className="px-3 py-2 text-sm font-medium rounded-md text-light"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  EXPLORE
                </HashLink>

                <HashLink
                  to="/#select-plan"
                  className="px-3 py-2 text-sm font-medium rounded-md text-light"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  MEMBERSHIP PLANS
                </HashLink>
                {/* 
                <HashLink
                  to="/#discover"
                  className="px-3 py-2 text-sm font-medium rounded-md text-light"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  DISCOVER
                </HashLink> */}

                <HashLink
                  to="/#contact"
                  className="px-3 py-2 text-sm font-medium rounded-md text-light"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  CONTACT US
                </HashLink>

                <HashLink
                  to="/become-partner"
                  className="px-3 py-2 text-sm font-medium rounded-md text-light"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  BECOME A PARTNER
                </HashLink>

                {isAuth ? (
                  <div className="relative">
                    <div
                      className="cursor-pointer"
                      onClick={() => setIsNavuserOpen(!isNavuserOpen)}
                    >
                      <img
                        src={imgSrc}
                        alt={`${user.first_name} ${user.last_name} profile`}
                        className="inline mr-1 rounded-full w-11 h-11"
                      />{" "}
                      {user.first_name} {user.last_name}
                    </div>
                    <Transition
                      show={isNavuserOpen}
                      enter="transition ease-out duration-100 transform"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75 transform"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <NavUser
                        isOpen={isNavuserOpen}
                        onClose={onCloseNavuser}
                        user={user}
                      />
                    </Transition>
                  </div>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="px-2 py-1 border-4 rounded border-light text-light"
                    >
                      LOG IN
                    </Link>
                    <Link
                      to="/signup"
                      className="px-2 py-1 text-white border-4 rounded border-green bg-green"
                    >
                      SIGN UP
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex -mr-2 xl:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 bg-gray-900 rounded-md text-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-light"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </Container>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className="xl:hidden min-h-screen flex flex-col"
          id="mobile-menu"
          ref={ref}
        >
          <div
            ref={ref}
            className={`flex-1 shrink-0 basis-full min-h-full max-h-screen overflow-y-auto no-scrollbar flex flex-col items-center gap-2 px-2 pt-2 space-y-1 sm:px-3 ${
              isOpen && isNavuserOpen ? "pb-80" : "pb-10"
            }`}
          >
            <HashLink
              to="/#about"
              className="block px-3 py-2 text-sm sm:text-base font-medium rounded-md hover:bg-gray-700 text-light"
              scroll={(el) => scrollWithOffset(el)}
            >
              ABOUT
            </HashLink>

            <HashLink
              to="/#select-plan"
              className="block px-3 py-2 text-sm sm:text-base font-medium rounded-md text-light"
              scroll={(el) => scrollWithOffset(el)}
            >
              MEMBERS PLANS
            </HashLink>

            <HashLink
              to="/discover"
              className="block px-3 py-2 text-sm sm:text-base font-medium rounded-md text-light"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => setIsOpen(false)}
            >
              DISCOVER
            </HashLink>

            <HashLink
              to="/#contact"
              className="block px-3 py-2 text-sm sm:text-base font-medium rounded-md text-light"
              scroll={(el) => scrollWithOffset(el)}
            >
              CONTACT US
            </HashLink>

            <HashLink
              to="become-partner"
              className="block px-3 py-2 text-sm sm:text-base font-medium rounded-md text-light"
              scroll={(el) => scrollWithOffset(el)}
              onClick={() => setIsOpen(false)}
            >
              BECOME A PARTNER
            </HashLink>
            {isAuth ? (
              <div className="relative">
                <span
                  className="cursor-pointer"
                  onClick={() => setIsNavuserOpen(!isNavuserOpen)}
                >
                  <img
                    src={imgSrc}
                    alt={`${user.first_name} ${user.last_name} profile`}
                    className="inline mr-1 rounded-full w-11 h-11"
                  />{" "}
                  {user.first_name} {user.last_name}
                </span>
                <Transition
                  show={isNavuserOpen}
                  enter="transition ease-out duration-100 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <NavUser
                    isOpen={isNavuserOpen}
                    onClose={onCloseNavuser}
                    user={user}
                  />
                </Transition>
              </div>
            ) : (
              <>
                <Link
                  to="/login"
                  className="block px-2 py-1 mx-auto text-base font-medium border-2 rounded border-light text-light w-fit"
                  onClick={() => setIsOpen(false)}
                >
                  LOG IN
                </Link>
                <Link
                  to="/signup"
                  className="block px-2 py-1 mx-auto text-base font-medium text-white border-2 rounded border-green bg-green w-fit"
                  onClick={() => setIsOpen(false)}
                >
                  SIGN UP
                </Link>
              </>
            )}
          </div>
        </div>
      </Transition>
    </nav>
  );
};

export default Navbar;
