import { ContactConsumer } from "../contexts/ContactContext";
import Message from "./Message";
import fitWoman from "../assets/images/fit_woman.jpg";
import gradient from "../assets/images/ask_gradient.svg";
import bgContact from "../assets/images/bg_contact.jpg";
import Container from "./Container";

const Ask = () => {
  const {
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    handleSubmit,
    successMsg,
    errMsg,
  } = ContactConsumer();

  return (
    <section className="bg-dark text-light relative flex py-20" id="contact">
      <Container className="flex gap-10">
        {/* <img
        src={fitWoman}
        alt="Fitness woman"
        className="absolute top-0 right-0 hidden object-cover w-1/2 h-full xl:block"
      /> */}
        {/* <img
        src={gradient}
        alt="gradient"
        className="absolute hidden h-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 xl:block"
      /> */}
        <div className="w-full basis-full lg:w-1/2 lg:basis-1/2 flex items-center text-center xl:text-left justify-center xl:justify-start">
          <div>
            <div className="mb-6">
              <h2 className="text-6xl font-bold mb-6">Have a Question?</h2>
              <p className="font-semibold tracking-wide text-xl">
                Let us answer all your questions. We respond within 24 hours.{" "}
                <br /> Feel free to ask your questions
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-wrap gap-3 mb-4 text-black">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="flex-1 p-3 rounded placeholder:text-dark-400"
                />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="flex-1 p-3 rounded placeholder:text-dark-400"
                />
              </div>
              <div className="flex mb-6">
                <textarea
                  name="question"
                  id="question"
                  placeholder="Your Question"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="flex-1 h-24 p-3 rounded text-black placeholder:text-dark-400"
                />
              </div>

              <Message msgType="success" message={successMsg} />
              <Message msgType="error" message={errMsg} />

              <button type="submit" className="p-3 bg-green text-white rounded">
                SEND A QUESTION
              </button>
            </form>
          </div>
        </div>
        <div className="object-cover w-1/2 basis-1/2 overflow-hidden hidden lg:inline-flex">
          <img src={bgContact} alt="gym" className="w-full h-full" />
        </div>
      </Container>
    </section>
  );
};

export default Ask;
