import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { LoginConsumer } from "./LoginContext";
import { getAge } from "../utils/Helpers";

const AccountSettingsContext = createContext(null);

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;

const AccountSettingsProvider = ({ children }) => {
  const { user, logoutUser, imgSrc } = LoginConsumer();
  const userId = user.id;
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);
  const [email, setEmail] = useState(user.user_name);
  const [accountEmail, setAccountEmail] = useState("");
  const [prevPassword, setPrevPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validMatchPassword, setValidMatchPassword] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [newEmailPassword, setNewEmailPassword] = useState("");
  const [isHavePlan, setIsHavePlan] = useState(true);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [passwordInputIcon, setPasswordinputIcon] = useState(<BsEyeFill />);
  const [selectedDate, setSelectedDate] = useState(new Date(user.birth_date));
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showMsg, setShowMsg] = useState(true);
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const resetToken = searchParams.get("token");

  const showHidePassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
      setPasswordinputIcon(<BsEyeSlashFill />);
    } else {
      setPasswordInputType("password");
      setPasswordinputIcon(<BsEyeFill />);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_ENV === "STAGING"
          ? `https://stage.srv.apicalfitness.com/api/users/${userId}/change-password/`
          : `https://srv.apicalfitness.com/api/users/${userId}/change-password/`,
        JSON.stringify({
          current_password: prevPassword,
          password: newPassword,
          password_confirmation: repeatNewPassword,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
            "App-Type": "Web",
          },
        }
      );

      if (response.status === 200) {
        navigate("/login", { replace: true });
        logoutUser();
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response, verify your internet connection");
        setShowMsg(true);
      } else if (
        err?.response.status === 422 &&
        err?.response.data.error === "incorrect_password"
      ) {
        setErrMsg("Incorrect current password, please try again.");
        setShowMsg(true);
      } else {
        setErrMsg("Operation Failed");
        setShowMsg(true);
      }
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_FORGET_PWD,
        JSON.stringify({
          email: accountEmail,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 200) {
        setSuccessMsg(
          "We have sent a password recovery instruction to your email, please check it! (Check Spam if you don't find it)"
        );
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response");
      } else if (
        err?.response.status === 422 &&
        err?.response.data.error === "user_not_found"
      ) {
        setErrMsg("Incorrect Email, please try again.");
        setShowMsg(true);
      } else {
        setErrMsg("Operation Failed");
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const v = PWD_REGEX.test(newPassword);
    if (!v) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_RENEW_PWD,
        JSON.stringify({
          token: resetToken,
          password: newPassword,
          password_confirmation: repeatNewPassword,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "App-Type": "Web",
          },
        }
      );

      if (response.status === 200) {
        navigate("/login", { replace: true });
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response!");
      } else {
        setErrMsg("Operation Failed!");
      }
    }
  };

  const handleUpdatePersonalData = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        process.env.REACT_APP_ENV === "STAGING"
          ? `https://stage.srv.apicalfitness.com/api/users/${userId}/`
          : `https://srv.apicalfitness.com/api/users/${userId}/`,
        JSON.stringify({
          image: imgSrc,
          first_name: firstName || user.first_name,
          last_name: lastName || user.last_name,
          age: selectedDate
            ? getAge(selectedDate.toISOString().slice(0, 10))
            : user.age,
          package: user.package,
          phone_number: phoneNumber || user.phone_number,
          birth_date:
            selectedDate.toISOString().slice(0, 10) || user.birth_date,
          discount: user.discount || 0,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 200) {
        setSuccessMsg("Your personal data is updated successfully!");
        setShowMsg(true);
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response");
        setShowMsg(true);
      } else if (
        err?.response.status === 422 &&
        err?.response.data.error === "user_image_cannot_update_for_three_months"
      ) {
        setErrMsg(
          "You can change your personal information once every three months. Contact us if you want to change it immediately."
        );
        setShowMsg(true);
      } else {
        setErrMsg("Operation Failed");
        setShowMsg(true);
      }
    }
  };

  const handleChangeEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        process.env.REACT_APP_ENV === "STAGING"
          ? `https://stage.srv.apicalfitness.com/api/users/${userId}/change-email/`
          : `https://srv.apicalfitness.com/api/users/${userId}/change-email/`,
        JSON.stringify({
          email: newEmail,
          password: newEmailPassword,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
            "App-Type": "Web",
          },
        }
      );
      if (response.status === 200) {
        setSuccessMsg("Your email is updated, please verify your inbox");
        setShowMsg(true);
      } else if (response.status === 422) {
        errMsg("Password incorrect, Try again");
        setShowMsg(true);
      }
    } catch (err) {
      if (!err.response) {
        setErrMsg("No Server Response");
        setShowMsg(true);
      } else if (
        err?.response.status === 422 &&
        err?.response.data.error === "incorrect_password"
      ) {
        setErrMsg("Incorrect password, please try again.");
        setShowMsg(true);
      } else if (
        err?.response.status === 422 &&
        err?.response.data.error === "email_already_exists"
      ) {
        setErrMsg("This user is already exists!");
        setShowMsg(true);
      }
    }
  };

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(newPassword));
    setValidMatchPassword(newPassword === repeatNewPassword);
  }, [newPassword, repeatNewPassword]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMsg(false);
      setSuccessMsg("");
      setErrMsg("");
    }, 10000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showMsg]);

  return (
    <AccountSettingsContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        prevPassword,
        passwordInputType,
        setPasswordInputType,
        passwordInputIcon,
        setPasswordinputIcon,
        setPrevPassword,
        newPassword,
        setNewPassword,
        showHidePassword,
        repeatNewPassword,
        setRepeatNewPassword,
        accountEmail,
        setAccountEmail,
        newEmail,
        setNewEmail,
        newEmailPassword,
        setNewEmailPassword,
        isHavePlan,
        setIsHavePlan,
        selectedDate,
        setSelectedDate,
        handleUpdatePersonalData,
        handleChangePassword,
        handleChangeEmail,
        handleForgotPassword,
        handleResetPassword,
        errMsg,
        successMsg,
        validPassword,
        validMatchPassword,
      }}
    >
      {children}
    </AccountSettingsContext.Provider>
  );
};

const AccountSettingsConsumer = () => {
  return useContext(AccountSettingsContext);
};

export { AccountSettingsProvider, AccountSettingsConsumer };
