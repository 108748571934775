import { Navigate } from "react-router-dom";
import { LoginConsumer } from "../contexts/LoginContext";

const RequireUnauth = ({ children }) => {
  const { isAuth } = LoginConsumer();

  if (isAuth) {
    return <Navigate to="/" />;
  }
  return children;
};

export default RequireUnauth;
