import { ContactProvider } from "../contexts/ContactContext";
// Components
import About from "../components/About";
import Ask from "../components/Ask";
import Categories from "../components/Categories";
import DiscoverComponent from "../components/partner/DiscoverComponent";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import SportCategories from "../components/sport_categories/SportCategories";
import SelectPlan from "../components/SelectPlan";
import NewFeatures from "../components/NewFeatures";
import BestSelection from "../components/BestSelection";
import Explore from "../components/explore/Explore";
import Testimonials from "../components/Testimonials";
import FAQSection from "../components/FAQSection";

const Home = () => {
  return (
    <>
      <main className="bg-dark text-white">
        <Hero />
        <NewFeatures />
        <BestSelection />
        <Explore />
        {/* <About /> */}
        {/* <SportCategories /> */}
        <SelectPlan />
        {/* <Testimonials /> */}
        <FAQSection />
        {/* <DiscoverComponent mode="dark" /> */}
        {/* <Categories /> */}
        {/* <Features mode="dark" /> */}
        <ContactProvider>
          <Ask />
        </ContactProvider>
      </main>
      <Footer />
    </>
  );
};

export default Home;
