import Facilities from "./Facilities";
import ViewMap from "./ViewMap";
import { PartnersConsumer } from "../../contexts/PartnersContext";
import PuffLoader from "react-spinners/PuffLoader";
import Container from "../Container";

function Explore() {
  const { partners, loading } = PartnersConsumer();

  return (
    <section
      id="explore"
      className="min-h-screen py-14 gradient-yellow-to-bottom text-dark"
    >
      <p className="text-4xl text-center font-bold">Explore</p>
      {loading ? (
        <div className="w-full flex items-center justify-center py-4 px-4">
          <PuffLoader color="#25AB75" />
        </div>
      ) : (
        <Container>
          <ViewMap partners={partners} />
          <Facilities partners={partners?.slice(0, 4)} />
        </Container>
      )}
    </section>
  );
}

export default Explore;
