import ReactDOM from "react-dom";
import { FileUploader } from "react-drag-drop-files";
import folderIcon from "../../assets/icons/folder-icon.png";

const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

const ChangePic = ({ isOpen, saveImg, onCloseAvatarEdit }) => {
  const handleChange = (file) => {
    saveImg(file[0]);
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      onClick={onCloseAvatarEdit}
      className="fixed top-0 left-0 bg-dark bg-opacity-70 w-full h-screen z-50 flex items-center justify-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="rounded overflow-hidden relative p-4"
        style={{ backgroundColor: "hsl(156, 64%, 88%)" }}
      >
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          children={<FileUploaderChildren />}
          classes="drop_area"
        />
      </div>
    </div>,
    document.getElementById("modal")
  );
};

const FileUploaderChildren = () => {
  return (
    <div className="text-dark p-2">
      <div className="border-2 p-4 border-dashed border-green rounded text-center">
        <img src={folderIcon} alt="" className="w-20 h-20 mx-auto" />
        <p className="text-md font-semibold py-2">
          Drag your photo here to start uploading.
        </p>
        <span className="break-line text-center relative font-semibold">
          OR
        </span>
        <button className="py-2 px-3 bg-green text-white rounded block mx-auto mt-3">
          Browse Files
        </button>
      </div>
    </div>
  );
};

export default ChangePic;
