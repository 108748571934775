import { useEffect } from "react";
import { Link } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import { AccountSettingsConsumer } from "../../contexts/AccountSettingsContext";
import SettingsContainer from "./SettingsContainer";
import Message from "../Message";
// Icons
import { BsInfoCircle } from "react-icons/bs";
import uncheckedIcon from "../../assets/icons/uncheck_red.svg";
import checkedIcon from "../../assets/icons/checked.svg";

const ChangePassword = () => {
  const {
    prevPassword,
    setPrevPassword,
    passwordInputType,
    newPassword,
    showHidePassword,
    passwordInputIcon,
    setNewPassword,
    repeatNewPassword,
    setRepeatNewPassword,
    handleChangePassword,
    validPassword,
    validMatchPassword,
    errMsg,
  } = AccountSettingsConsumer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SettingsContainer>
      <div>
        <h3 className="text-3xl text-center lg:text-left font-semibold uppercase mb-3">
          CHANGE PASSWORD
        </h3>
        <p className="mb-6">
          Your new password must be different from previously used password
        </p>
        <form className="mt-5" onSubmit={handleChangePassword}>
          <div className="mb-3 mt-6 relative w-full">
            <input
              type="password"
              name="prev_password"
              placeholder="Your previous password"
              value={prevPassword}
              onChange={(e) => setPrevPassword(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
            <Link
              to="forgot-password"
              className="text-right text-green ml-auto block w-fit mt-2"
            >
              Forgot password?
            </Link>
          </div>
          <div className="my-6 relative">
            <input
              type={passwordInputType}
              name="password"
              placeholder="Your new Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
            <label
              id="password"
              aria-label=""
              className="bg-dark-600 text-xs px-1 absolute -top-2 left-2 rounded"
            >
              Create password
            </label>
            <span
              className="absolute right-0 top-4 w-8 h-8 text-dark-400 cursor-pointer"
              onClick={showHidePassword}
            >
              {passwordInputIcon}
            </span>
          </div>

          <PasswordChecklist
            rules={["capital", "lowercase", "number", "minLength"]}
            minLength={6}
            value={newPassword}
            messages={{
              capital: "Uppercase letters",
              lowercase: "Lowercase letters",
              number: "Numbers",
              minLength: "Password has more than 6 characters",
            }}
            iconComponents={{
              ValidIcon: <img src={checkedIcon} alt="Checked" />,
              InvalidIcon: <img src={uncheckedIcon} alt="Unchecked" />,
            }}
            validColor="#25ab75"
            invalidColor="red"
            className="text-left my-3 password-checklist"
          />

          <div className="mt-6 mb-12 relative w-full">
            <input
              type="password"
              name="repeat_password"
              placeholder="Repeat new password"
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
              className="px-2 py-3 bg-dark-800 text-light font-light rounded placeholder:text-light focus:outline focus:outline-green w-full block"
            />
            <span className="absolute right-0 top-4 w-8 h-8">
              {validMatchPassword && repeatNewPassword ? (
                <img src={checkedIcon} alt="Valid password" />
              ) : validMatchPassword || !repeatNewPassword ? (
                <img src={uncheckedIcon} alt="Unchecked icon" />
              ) : (
                <img src={uncheckedIcon} alt="Unchecked icon" />
              )}
            </span>
            {!validMatchPassword ? (
              <p className="my-3 text-white flex items-center gap-2">
                <BsInfoCircle size="1.4rem" /> Must match the new password input
                field.
              </p>
            ) : null}
          </div>

          <Message msgType="error" message={errMsg} text="left" />

          <button
            type="submit"
            className="p-3 bg-green text-white rounded block w-fit mx-auto lg:ml-0"
            disabled={!validPassword || !validMatchPassword ? true : false}
          >
            SAVE CHANGES
          </button>
        </form>
      </div>
    </SettingsContainer>
  );
};

export default ChangePassword;
