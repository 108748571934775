import testimonial1 from "../assets/images/testimonials/testimonial_1.png";
import testimonial2 from "../assets/images/testimonials/testimonial_2.png";
import testimonial3 from "../assets/images/testimonials/testimonial_3.png";
import { useEffect } from "react";
import Glide from "@glidejs/glide";
import Container from "./Container";

const sliderConfiguration = {
  gap: 0,
  perView: 1,
  startAt: 0,
  type: "slider",
  focusAt: "center",
  peek: { before: 0, after: 100 },
};

function Testimonials() {
  let slider;

  useEffect(() => {
    slider = new Glide("#testimonials_slider.glide", sliderConfiguration);
    slider.mount();
  }, []);

  return (
    <section className="py-24">
      <Container>
        {/* For Desktop View */}
        <div className="my-8 px-8 hidden lg:flex gap-8">
          {testimonialsArr.map((testimonial, index) => {
            return (
              <div
                className="flex flex-col items-center justify-center gap-2"
                key={index}
              >
                <img
                  src={testimonial.image}
                  alt="Testimonial"
                  className="w-[200px]"
                />
                <p className="text-2xl font-bold uppercase text-center">
                  {testimonial.name}
                </p>
                <p className="text-center">{testimonial.text}</p>
              </div>
            );
          })}
        </div>
      </Container>
      {/* For Mobile View */}
      <div id="testimonials_slider" className="glide lg:hidden">
        <div className="glide__track" data-glide-el="track">
          <ul id="testimonials_slider" className="glide__slides">
            {testimonialsArr.map((testimonial, index) => {
              return (
                <li
                  className="glide__slide slider flex flex-col items-center justify-center gap-2"
                  key={index}
                >
                  <img
                    src={testimonial.image}
                    alt="Testimonial"
                    className="w-[200px]"
                  />
                  <p className="text-2xl font-bold uppercase text-center">
                    {testimonial.name}
                  </p>
                  <p className="text-center">{testimonial.text}</p>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <div className="glide__arrows" data-glide-el="controls">
          <button
            className="glide__arrow glide__arrow--prev"
            data-glide-dir="<"
          >
            prev
          </button>
          <button
            className="glide__arrow glide__arrow--next"
            data-glide-dir=">"
          >
            next
          </button>
        </div> */}
      </div>
    </section>
  );
}

export default Testimonials;

const testimonialsArr = [
  {
    image: testimonial1,
    name: "Jeckson G.",
    text: "Lorem ipsum dolor sit amet consectetur. Neque imperdiet volutpat nisl sed.",
  },
  {
    image: testimonial2,
    name: "Meggie S.",
    text: "Lorem ipsum dolor sit amet consectetur. Neque imperdiet volutpat nisl sed.",
  },
  {
    image: testimonial3,
    name: "Aida P.",
    text: "Lorem ipsum dolor sit amet consectetur. Neque imperdiet volutpat nisl sed.",
  },
];
