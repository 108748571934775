import Select from "react-select";
import makeAnimated from "react-select/animated";
import partnersImg from "../assets/images/partner2.jpg";
import { PartnerConsumer } from "../contexts/BecomePartnerContext";
import Message from "../components/Message";
import Footer from "../components/Footer";
import Container from "../components/Container";

const BecomePartner = () => {
  const animatedComponents = makeAnimated();
  const {
    name,
    setName,
    email,
    setEmail,
    address,
    setAddress,
    phone,
    setPhone,
    formCategories,
    handleChangeCategories,
    subcategories,
    filteredSubcategories,
    handleChangeSubcategories,
    callAvailability,
    setCallAvailability,
    handleSubmit,
    successMsg,
    errMsg,
    filteredFormCategories,
  } = PartnerConsumer();

  return (
    <>
      <main className="bg-dark text-white py-12 min-h-screen flex items-center relative">
        <Container className="lg:flex items-center justify-between gap-6">
          <div style={{ flex: "2" }}>
            <h1 className="text-6xl mb-5 font-semibold">Become A Partner</h1>
            <p className="text-lg">
              Becoming our partner offers a multitude of benefits for facility
              providers looking to expand their customer base and increase
              revenue. Our website provides a unique platform that connects
              various fitness and recreation providers such as gyms, athletic
              clubs, swimming clubs, dance studios, and more. By partnering with
              us, you'll have access to a vast network of potential customers,
              giving you the opportunity to showcase your facility's distinctive
              features and services to a wider audience. Additionally, our low
              3% service fee ensures that you receive a valuable service at an
              even more affordable price. We also cover the advertisement
              expenses, ensuring that your facility reaches a broader audience
              without any additional cost to you. Furthermore, our platform has
              attracted a significant number of corporate clients who are
              looking for fitness and recreation providers for their employees.
              This presents a tremendous opportunity for facility providers to
              expand their customer base even further and increase revenue. You
              can apply for partnership by filling out the form below. So why
              wait? Partner with us today and take advantage of the many
              benefits our website has to offer.
            </p>
            <form className="mt-8" onSubmit={handleSubmit}>
              <div className="flex flex-col sm:flex-row gap-4 mb-6">
                <input
                  type="text"
                  name="organizationName"
                  placeholder="Name of the organization"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="sm:w-1/2 px-2 py-3 bg-dark-800 text-light rounded placeholder:text-dark-400"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="sm:w-1/2 px-2 py-3 bg-dark-800 text-light rounded placeholder:text-dark-400"
                />
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                  className="sm:w-1/2 px-2 py-3 bg-dark-800 text-light rounded placeholder:text-dark-400"
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-4 mb-6">
                <div className="flex-1">
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone number"
                    value={phone}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;

                      // if value is not blank, then test the regex
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setPhone(e.target.value);
                      }
                    }}
                    required
                    className="w-full px-2 py-3 bg-dark-800 text-light rounded placeholder:text-dark-400"
                  />
                </div>
                <div className="flex-1 text-dark-800 multiselect-container">
                  <Select
                    value={filteredFormCategories}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={formCategories}
                    placeholder="Categories"
                    onChange={handleChangeCategories}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        padding: ".3rem 0",
                        background: "rgb(27 27 27 / var(--tw-bg-opacity))",
                        boxShadow: "none",
                      }),
                      input: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "#25AB75",
                        outline: "#25AB75",
                      }),
                      menu: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "rgb(27 27 27 / var(--tw-bg-opacity))",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "rgb(250 250 249 / var(--tw-text-opacity))",
                        background: state.isSelected
                          ? "#25AB75"
                          : "rgb(27 27 27 / var(--tw-bg-opacity))",
                        "&:hover": {
                          background: "#25AB75",
                        },
                      }),
                    }}
                  />
                </div>
                <div className="flex-1 text-dark-800 multiselect-container">
                  <Select
                    value={filteredSubcategories}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={subcategories}
                    placeholder="Sub-categories"
                    onChange={handleChangeSubcategories}
                    required
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        padding: ".3rem 0",
                        background: "rgb(27 27 27 / var(--tw-bg-opacity))",
                        boxShadow: "none",
                      }),
                      input: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "#25AB75",
                        outline: "#25AB75",
                      }),
                      menu: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "rgb(27 27 27 / var(--tw-bg-opacity))",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "rgb(250 250 249 / var(--tw-text-opacity))",
                        background: state.isSelected
                          ? "#25AB75"
                          : "rgb(27 27 27 / var(--tw-bg-opacity))",
                        "&:hover": {
                          background: "#25AB75",
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              {/* <div className="flex gap-4 my-4">
              <input
                type="checkbox"
                name="call_availability"
                id="call_availability"
                className="appearance-none"
                value={callAvailability}
                onChange={(e) => setCallAvailability(!callAvailability)}
              />
              <label
                htmlFor="call_availability"
                className="p-2 relative before:absolute before:w-4 before:h-4 before:z-10 before:text-green after:absolute after:content[''] after:border-2 after:border-green after:w-5 after:h-5 after:bg-light after:rounded cursor-pointer"
              >
                Availability for a phone call
              </label>
            </div> */}
              <Message message={successMsg} msgType="success" />
              <Message message={errMsg} msgType="error" />
              <button
                className="bg-green text-white p-3 rounded text-center"
                onClick={handleSubmit}
                type="reset"
              >
                BECOME A PARTNER
              </button>
            </form>
          </div>
          <div className="hidden lg:block w-44 flex-1">
            <img
              src={partnersImg}
              alt="Partners"
              className="w-full h-full"
              style={{ height: "550px" }}
            />
          </div>
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default BecomePartner;
