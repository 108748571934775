import React, { useRef, useCallback } from "react";
import { Link } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, EffectFade } from "swiper";
import SwiperCore from "swiper/core";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import defaultImg from "../../assets/images/rundofase_2.jpg";
import Facilities from "./Facilities";

SwiperCore.use([Navigation]);

function FaciltiesCarousel({ facilities }) {
  const sliderRef = useRef(null);
  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div>
      <Swiper
        slidesPerView={1.5}
        spaceBetween={10}
        speed={500}
        breakpoints={{
          320: { slidesPerView: 1, centeredSlides: true },
          640: { slidesPerView: 1.5, centeredSlides: false },
        }}
        navigation={{
          nextEl: "nextSlide",
          prevEl: null,
        }}
        modules={[Navigation, EffectFade]}
        // loop={images.length > 3}
        effect
        className="mySwiper"
        style={{ position: "unset" }}
        ref={sliderRef}
      >
        {facilities.map((facility, index) => {
          return (
            <SwiperSlide key={index}>
              <Link to={`/partners/${facility.id}`} className="w-full h-full">
                <div className="w-full h-40 rounded-lg overflow-hidden">
                  <img
                    src={facility.images[0]?.image || defaultImg}
                    alt="facility"
                    className="h-full w-full object-cover"
                  />
                </div>
                <p className="font-bold my-2 text-2xl">{facility.name}</p>
                <p className="text-xl">{facility.categories[0]?.title}</p>
                <p className="text-sm">{facility.address_line1}</p>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default FaciltiesCarousel;
