import { Navigate } from "react-router-dom";
import { LoginConsumer } from "../contexts/LoginContext";

const RequireAuth = ({ children }) => {
  const { isAuth } = LoginConsumer();

  if (isAuth) {
    return children;
  }
  return <Navigate to="/login" />;
};

export default RequireAuth;
