import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import bgImg from "../assets/images/bg_faqs.jpg";
import Ask from "../components/Ask";
import Footer from "../components/Footer";
import DiscoverComponent from "../components/partner/DiscoverComponent";

function DiscoverPage() {
  const [searchParams] = useSearchParams();
  const selectedPlan = searchParams.get("selected_plan");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="">
      {/* Header */}
      <DiscoverComponent mode="dark" selectedPlan={selectedPlan} />
      <Footer />
    </section>
  );
}


export default DiscoverPage;