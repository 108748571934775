import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { LoginConsumer } from "../../contexts/LoginContext";
// Icons
import { FiSettings, FiLogOut } from "react-icons/fi";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { HiOutlineMail, HiOutlineUser } from "react-icons/hi";
import { BsKey } from "react-icons/bs";

const NavUser = ({ isOpen, onClose }) => {
  const { logoutUser } = LoginConsumer();
  const isLargeScreen = useMediaQuery({ minWidth: 1024 });

  if (!isOpen) return null;
  return (
    <div
      className={`w-60 p-1 absolute ${
        isLargeScreen ? "right-0" : "left-0"
      } z-50 text-white bg-dark cursor-auto rounded`}
      style={isLargeScreen ? { bottom: "-320px" } : { bottom: "-240px" }}
    >
      <Link
        to="/download-app"
        className="border border-b-0 px-2 py-1.5 lg:py-3 flex items-center gap-2 border-dark-600 hover:bg-green hover:text-white"
        onClick={onClose}
      >
        <HiDevicePhoneMobile /> Download App
      </Link>
      <Link
        to="/account-settings"
        className="border border-b-0 px-2 py-1.5 lg:py-3 flex items-center gap-2 border-dark-600 hover:bg-green"
        onClick={() => {
          onClose();
        }}
      >
        <FiSettings /> General Profile Settings
      </Link>
      <Link
        to="/account-settings/change-password"
        className="border border-b-0 px-2 py-1.5 lg:py-3 flex items-center gap-2 border-dark-600 hover:bg-green"
        onClick={onClose}
      >
        <BsKey /> Change Password
      </Link>
      <Link
        to="/account-settings/change-email"
        className="border border-b-0 px-2 py-1.5 lg:py-3 flex items-center gap-2 border-dark-600 hover:bg-green"
        onClick={() => {
          onClose();
        }}
      >
        <HiOutlineMail /> Change Email
      </Link>
      <Link
        to="/account-settings/change-membership"
        className="border border-b-0 px-2 py-1.5 lg:py-3 flex items-center gap-2 border-dark-600 hover:bg-green"
        onClick={onClose}
      >
        <HiOutlineUser /> Manage Membership
      </Link>
      <Link
        className="border px-2 py-1.5 lg:py-3 flex items-center gap-2 border-dark-600 hover:bg-green"
        onClick={() => {
          logoutUser();
          onClose();
        }}
      >
        <FiLogOut /> Logout
      </Link>
    </div>
  );
};

export default NavUser;
